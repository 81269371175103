<template>
  <div
    class="table-container"
    :class="isMobile && tableData ? 'fixed-height' : ''">
    <canvas
      ref="tableCanvas"
      style="position:absolute;top:0;left:0;z-index:99;"
      :width="canvasW"
      :height="canvasH" />
    <b-table-simple>
      <!-- column group -->
      <!-- 开奖期数 -->
      <colgroup><col></colgroup>
      <!-- 开奖号码 -->
      <colgroup><col></colgroup>
      <!-- 冠亚和 -->
      <colgroup><col></colgroup>
      <!-- 值 -->
      <colgroup>
        <col
          v-for="(subHead, subIdx) in hzSet"
          :key="'set-sub-col-d-' + subIdx">
      </colgroup>

      <!-- table head -->
      <b-thead>
        <b-tr>
          <!-- 开奖期数 -->
          <b-th>
            开奖期数
          </b-th>
          <!-- 开奖号码 -->
          <b-th>
            开奖号码
          </b-th>
          <!-- 冠亚和 -->
          <b-th>
            冠亚和
          </b-th>
          <!-- 值 -->
          <b-th
            v-for="(hzHead, idx) in hzSet"
            :key="'sub-head-hz-col-' + idx">
            {{ hzHead }}
          </b-th>
        </b-tr>
      </b-thead>

      <!-- table body -->
      <b-tbody ref="tableBody">
        <b-tr v-if="!tableData">
          <b-td
            :colspan="tableColNum"
            :class="showMsg == 'error' ? 'error-msg' : ''">
            {{tableMsg[showMsg]}}
          </b-td>
        </b-tr>
        <b-tr
          v-else
          v-for="(lot, idx) in tableData"
          :key="lot.id">
          <!-- 开奖期数 -->
          <b-td>{{ lot.issue }}</b-td>
          <!-- 开奖号码 -->
          <b-td>
            <div class="result-nums-container">
              <div
                v-for="(num, numIdx) in generateLotNums(lot.code)"
                :key="'lot-num-' + lot.id + numIdx">{{ generateResultNum(num) }}</div>
            </div>
          </b-td>
          <!-- 冠亚和 -->
          <b-td>{{ getHzNum(lot) }}</b-td>
          <!-- 值 -->
          <b-td
            v-for="(hzNum, hzIdx) in hzSet"
            :key="'row-' + lot.id + '-idx-' + hzIdx"
            :class="generateCellClass(lot, idx, hzIdx)">
            <div
              class="num-container"
              :class="generateLotNumClass(lot, hzIdx)">
              {{ generateCellContent(lot, idx, hzIdx) }}
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { allLots } from '@/constants/lots'
import { getLotWinNumHistory } from '@/api/client/lot'
import { getLotInfoById } from '@/utils/lot'
import { range, findIndex } from 'lodash'
import { fillDigit } from '@/utils/string'
import { isMobile } from '@/utils/device'

export default {
  props: {
    updateStatus: {
      type: Boolean,
      required: true
    },
    lotTotalIssue: {
      type: Number,
      required: true
    },
    pageSize: {
      type: [Number, String],
      required: true
    },
    leaksStatus: {
      type: Boolean,
      required: true
    },
    leaksLevelStatus: {
      type: Boolean,
      required: true
    },
    showLineStatus: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    lotId: {
      get () {
        if (!this.$route.query.id) {
          return allLots[0].children[0].id
        }
        return this.$route.query.id
      }
    },
    lotInfo: {
      get () {
        return (
          getLotInfoById(this.lotId)
        )
      }
    },
    chartRule: {
      get () {
        return this.lotInfo.rule.chart
      }
    },
    query: {
      get () {
        const objQuery = {
          // date: '2021-03-01',
          code: this.lotId,
          page: 1
        }

        switch (typeof this.pageSize) {
          case 'string':
            objQuery.date = this.pageSize
            break
          case 'number':
            // objQuery.pageSize = this.pageSize
            // objQuery.date = null
            objQuery.latest = this.pageSize
            break
        }

        return objQuery
      }
    },
    resultMinNum: {
      get () {
        return this.lotInfo.rule.chart.resultMinNum
      }
    },
    resultMaxNum: {
      get () {
        return this.lotInfo.rule.chart.resultMaxNum
      }
    },
    hzMinNum: {
      get () {
        let result = 0
        for (let i = 0; i < 2; i++) {
          result += this.resultMinNum + i
        }
        return result
      }
    },
    hzMaxNum: {
      get () {
        let result = 0
        for (let i = 0; i < 2; i++) {
          result += this.resultMaxNum - i
        }
        return result
      }
    },
    hzSet: {
      get () {
        return range(this.hzMinNum, this.hzMaxNum + 1)
      }
    },
    tableColNum: {
      get () {
        return this.hzSetLen + 3
      }
    },
    hzSetLen: {
      get () {
        return this.hzSet.length
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  data () {
    return {
      firstLoaded: true,
      canvasW: 0,
      canvasH: 0,
      tableData: null,
      showMsg: '',
      tableMsg: {
        error: '發生错误，请稍后再试',
        empty: '目前尚无资料',
        loading: '获取资料中...'
      }
    }
  },
  methods: {
    init () {
      this.showMsg = 'loading'
      getLotWinNumHistory(this.query)
        .then(res => {
          if (res.status === resStatus.OK) {
            if (res.data.length === 0) {
              this.showMsg = 'empty'
              this.emptyPageData()
            } else {
              this.tableData = res.data
            }
          } else {
            this.showMsg = 'error'
            this.emptyPageData()
          }
        })
    },
    emptyPageData () {
      this.tableData = null
      this.canvasW = 0
      this.canvasH = 0
    },
    getQueryPageSize () {
      switch (typeof this.pageSize) {
        case 'string':
          return 50
        case 'number':
          return this.pageSize
      }
    },
    getQueryDate () {
      switch (typeof this.pageSize) {
        case 'string':
          return this.pageSize
        case 'number':
          return null
      }
    },
    generateLotNums (numStr) {
      return numStr.split(',')
    },
    generateResultNum (num) {
      if (this.chartRule.resultFillZero) {
        return fillDigit(num, 2, '0')
      }
      return num
    },
    // 值
    generateCellClass (lot, idx, hzIdx) {
      const cellClass = 'digit-default'
      const groupNextNumIdx = this.getGroupNextNumIdx(0, hzIdx)
      if (
        !this.showLotNum(hzIdx, lot) &&
        this.leaksLevelStatus &&
        groupNextNumIdx - idx > 0
      ) {
        return `${cellClass} leaksLevel`
      }
      return `${cellClass} leaksLevel-no`
    },
    generateLotNumClass (lot, hzIdx) {
      if (this.showLotNum(hzIdx, lot)) {
        return `lot-num lot-num-col-${hzIdx}`
      }
      return 'not-lot-num'
    },
    generateCellContent (lot, idx, hzIdx) {
      const hzNum = this.getHzNum(lot)
      if (this.showLotNum(hzIdx, lot)) {
        // if (this.lotInfo.rule.chart.resultFillZero) {
        //   return fillDigit(hzNum, 2, '0')
        // }
        return hzNum
      } else {
        if (this.showCellContent(hzIdx, lot)) {
          return this.getLeaksNum(idx, hzIdx)
        }
        return ''
      }
    },
    getLotCodes (lot) {
      return lot.code.split(',')
    },
    getHzNum (lot) {
      const result = this.getLotCodes(lot)
      return Number(result[0]) + Number(result[1])
    },
    showLotNum (hzIdx, lot) {
      const hzNum = this.getHzNum(lot)

      return (hzIdx + this.hzMinNum === hzNum)
    },
    showCellContent (hzIdx, lot) {
      if (this.showLotNum(hzIdx, lot)) {
        return true
      }
      return this.leaksStatus
    },
    getLeaksNum (idx, hzIdx) {
      if (!this.tableData) {
        return ''
      }
      const groupNextNumIdx = this.getGroupNextNumIdx(idx, hzIdx)
      return groupNextNumIdx - idx
    },
    getGroupNextNumIdx (idx, hzIdx) {
      const subIdxNum = hzIdx % this.hzSetLen + this.hzMinNum
      const nextIdx = findIndex(this.tableData, (i) => {
        return this.getHzNum(i) === subIdxNum
      }, idx)
      if (nextIdx === -1) {
        return this.tableData.length
      }
      return nextIdx
    },
    // handle canvas
    handleDrawLine () {
      setTimeout(() => {
        const tbody = this.$refs.tableBody.$el
        this.lotTd = tbody.offsetHeight > 0 ? tbody.firstChild.children[3] : null
        if (
          tbody &&
          this.tableData &&
          this.tableData.length !== 0 &&
          this.lotTd
        ) {
          this.canvasW = tbody.offsetWidth
          this.canvasH = tbody.offsetHeight + (this.lotTd.offsetHeight * 2)
          // wait for component mounted
          setTimeout(() => {
            this.canvasDraw = this.$refs.tableCanvas.getContext('2d')
            this.clearCanvas()
            this.canvasDraw.strokeStyle = '#3cb0ec'
            this.drawBallLine()
          }, 10)
        } else {
          this.clearCanvas()
        }
      }, 10)
    },
    clearCanvas () {
      const canvas = this.$refs.tableCanvas
      if (this.canvasDraw) {
        this.canvasDraw.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight)
      }
    },
    drawBallLine () {
      if (this.showLineStatus) {
        for (let i = 0, c = this.tableData.length - 1; i < c; i++) {
          const start = this.getBallPosition(i)
          const end = this.getBallPosition(i + 1)
          this.drawOneLine(start, end)
        }
      }
    },
    getBallPosition (ballIdx) {
      const ball = this.$refs.tableBody.$el.querySelectorAll('.lot-num')[ballIdx]
      return {
        x: ball.parentElement.offsetLeft + (this.lotTd.offsetWidth / 2),
        y: ball.parentElement.offsetTop + (this.lotTd.offsetHeight / 2)
      }
    },
    drawOneLine (start, end) {
      // console.log('draw one line: ', start, end)
      const c = this.canvasDraw
      c.beginPath()
      c.moveTo(start.x, start.y)
      c.lineTo(end.x, end.y)
      c.stroke()
    }
  },
  created () {
    this.init()
  },
  watch: {
    updateStatus (val) {
      if (this.firstLoaded) {
        this.firstLoaded = false
      } else if (val) {
        this.init()
      }
    },
    pageSize (val) {
      switch (typeof val) {
        case 'string':
          this.query.pageSize = 100
          this.query.date = val
          break
        case 'number':
          this.query.latest = val
          // this.query.pageSize = val
          // this.query.date =
          break
      }
      this.init()
    },
    tableData (val) {
      if (val) {
        this.handleDrawLine()
      }
    },
    showLineStatus (val) {
      this.handleDrawLine()
    }
  }
}
</script>

<style lang="scss" scoped>
$bg-orange: #fbf6f0;
$bg-blue: #edf8fc;
$bg-green: #ecfcec;
$bg-purple: #ededfc;
$bg-emerald: #edfcf6;
$bg-yellow: #fcfced;
$bg-red: #fceded;
$bg-cyan: #edf2fc;
$bg-default: #edf2fc;

$circle-orange: #ee954b;
$circle-blue: #3594e3;
$circle-green: #55b810;
$circle-purple: #716cfa;
$circle-emerald: #4fb383;
$circle-yellow: #bed458;
$circle-red: #ce38bb;
$circle-cyan: #6bcdd0;
$circle-default: #6bcdd0;

$header-row-height: 43px;
$first-col-width: 12em;
$second-col-width: 15em;
// $col-min-width: 300px;
$num-cell-width: 18px;
.table-container {
  position: relative;
  height: max-content;
  overflow: auto;
}
table {
  // margin: 0 0 6px;
  thead {
    tr {
      th {
        border: 1px solid #dee2e6;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        border: 1px solid #dee2e6;
        vertical-align: middle;
      }
      .error-msg {color: #EB5757;}
      .result-nums-container {
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          margin-right: 5px;
          &:last-child{margin-right: 0;}
        }
      }
      .num-container {
        margin: 0 auto;
      }
      .lot-num {
        color: #ffffff;
        width: $num-cell-width;
        height: $num-cell-width;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 999;
      }
      .not-lot-num {
        width: $num-cell-width;
        height: $num-cell-width;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .leaksLevel {
        background-color: #9595c5 !important;
        color: #ffffff;
      }
      .digit-default {
        background-color: $bg-default;
        .lot-num {
          background-color: $circle-default;
        }
      }
      .digit-0 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-1 {
        background-color: $bg-blue;
        .lot-num {
          background-color: $circle-blue;
        }
      }
      .digit-2 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .digit-3 {
        background-color: $bg-purple;
        .lot-num {
          background-color: $circle-purple;
        }
      }
      .digit-4 {
        background-color: $bg-emerald;
        .lot-num {
          background-color: $circle-emerald;
        }
      }
      .digit-5 {
        background-color: $bg-yellow;
        .lot-num {
          background-color: $circle-yellow;
        }
      }
      .digit-6 {
        background-color: $bg-red;
        .lot-num {
          background-color: $circle-red;
        }
      }
      .digit-7 {
        background-color: $bg-cyan;
        .lot-num {
          background-color: $circle-cyan;
        }
      }
      .digit-8 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-9 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      // .feature-container-0 {
      //   .feature-item {
      //     background-color: $circle-green;
      //   }
      // }
      .feature-container-1 {
        .feature-item {
          background-color: $circle-purple;
        }
      }
      .feature-container-2 {
        .feature-item {
          background-color: $circle-purple;
        }
      }
      .feature-container-3 {
        .feature-item {
          background-color: $circle-green;
        }
      }
      .feature-container-4 {
        .feature-item {
          background-color: $circle-green;
        }
      }
      .feature-item {
        width: $num-cell-width;
        height: $num-cell-width;
        border-radius: 50%;
        color: #FFFFFF;
        text-align: center;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
            &:nth-child(3) {min-width: 5.5em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
            &:nth-child(3) {min-width: 5.5em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
        th:nth-child(3) {
          min-width: 5em;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
        th:nth-child(3) {
          min-width: 5em;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  // .b-table-sticky-header {
  //   max-height: 600px;
  // }
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
        th:nth-child(3) {
          min-width: 5em;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
</style>
