<template>
  <div class="content-container">
    <Header
      :selCol="selCol"
      :leaksStatus="leaksStatus"
      :leaksLevelStatus="leaksLevelStatus"
      :showLineStatus="showLineStatus"
      :pageSize="pageSize"
      @handleSelChanged="handleSelChanged"
      @handleLeaksStatus="handleLeaksStatus"
      @handleLeaksLevelStatus="handleLeaksLevelStatus"
      @handleShowLineStatus="handleShowLineStatus"
      @handleChangePageSize="handleChangePageSize" />
    <div class="main-container">
      <Table
        :updateStatus="updateStatus"
        :selCol="selCol"
        :pageSize="pageSize"
        :leaksStatus="leaksStatus"
        :leaksLevelStatus="leaksLevelStatus"
        :showLineStatus="showLineStatus" />
    </div>
  </div>
</template>

<script>
import Header from './Header'
import Table from './Table'

export default {
  props: {
    updateStatus: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Header,
    Table
  },
  data () {
    return {
      selCol: 'type-0',
      pageSize: 50,
      leaksStatus: true,
      leaksLevelStatus: false,
      showLineStatus: true
    }
  },
  methods: {
    handleUpdateStatus (boo) {
      this.$emit('handleUpdateStatus', boo)
    },
    handleSelChanged (str) {
      this.selCol = str
    },
    handleLeaksStatus (boo) {
      this.leaksStatus = boo
    },
    handleLeaksLevelStatus (boo) {
      this.leaksLevelStatus = boo
    },
    handleShowLineStatus (boo) {
      this.showLineStatus = boo
    },
    handleChangePageSize (num) {
      this.pageSize = num
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 575.98px) {
  .main-container{background-color: #FFF;}
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-container{background-color: #FFF;}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-container{padding: 0 15px 15px;}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-container{padding: 0 15px 15px;}
}
@media (min-width: 1200px) {
  .main-container{padding: 0 15px 15px;}
}
</style>
