<template>
  <div>
    <div class="top-bar">
      <div class="analysis-title">号码预测</div>
      <div v-show="isMobile" class="predict-percentage">中奖率：<span>{{winningPercent}}%</span></div>
      <div class="analysis-selector">
        <b-select
          v-model="params.param1"
          :options="seriseOpts"
          class="analysis-select"
          @change="getTypeOpts(true)"
        ></b-select>
        <b-select
          v-model="params.param2"
          :options="typeOpts"
          class="analysis-select"
          @change="getNumOpts(true)"
        ></b-select>
        <b-select
          v-model="params.param3"
          :options="numsOpts"
          class="analysis-select"
        ></b-select>
        <b-button class="predict-btn" @click="getPredictData">点击预测</b-button>
      </div>
      <div v-show="!isMobile" class="predict-percentage">中奖率：<span>{{winningPercent}}%</span></div>
    </div>
    <div class="predict-table">
      <b-table
        bordered
        :fixed="!isMobile"
        :sticky-header="isMobile"
        head-variant="light"
        thead-tr-class="predict-table-head"
        :fields="predictData.fields"
        :items="predictData.items"
        show-empty
        :empty-text="tableShowText">
        <template #cell(predict_code)="data">
          <div v-if="isMobile">{{ data.value }}</div>
          <div
            v-else
            class="fixed-width text-truncate"
            v-b-tooltip.hover.right
            :title=data.value>
            {{ data.value }}
          </div>
        </template>
        <template #cell(result)="data">
          <span v-html="data.value"></span>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { fillDigit } from '@/utils/string'
import resStatus from '@/constants/resStatus'
import { allLots } from '@/constants/lots'
import { getLotWinNumPredict } from '@/api/client/lot'
import { lottoApiType, analysisOptionValue as optionVal, analysisOptionName as optionName } from '@/constants/numAnalysisOptions'
import { isMobile } from "@/utils/device"

export default {
  data () {
    return {
      params: {
        param1: '',
        param2: '',
        param3: '',
        code: ''
      },
      seriseOpts: [],
      typeOpts: [],
      numsOpts: [],
      nextPredData: {
        issue: '',
        predict_code: '',
        code: '',
        result: '',
        win_code: ''
      },
      predictData: {
        fields: [
          { key: 'issue', label: '计划期数' },
          { key: 'predict_code', label: '预测号' },
          { key: 'code', label: '开奖号' },
          { key: 'result', label: isMobile ? '结果' : '计划结果' },
          { key: 'win_code', label: isMobile ? '中奖号' : '中奖号码' }
        ],
        items: [],
        message: {
          error: '无法取得数据，请稍后再试',
          loading: 'Loading...',
          empty: '目前尚无数据'
        }
      },
      needFillDigit: ['pk10', '11c5', 'kl10'],
      expectFillType: ['DT', 'BS', 'OE'],
      tableShowText: 'Loading...',
      winPercentage: 0
    }
  },
  props: ['updateStatus', 'lotLottery'],
  computed: {
    lotId: {
      get () {
        if (!this.$route.query.id) {
          return allLots[0].children[0].id
        }
        return this.$route.query.id
      }
    },
    lotType: {
      get () {
        const id = this.lotId
        let type = ''
        Object.keys(lottoApiType).forEach(function (key) {
          if (lottoApiType[key].find((item) => { return item === id })) {
            type = key
          }
        })
        return type
      }
    },
    winningPercent: {
      get () {
        return this.winPercentage
      },
      set (num) {
        const calRes = 100 * num / 20
        if (calRes.toString().indexOf('.') > -1) {
          this.winPercentage = calRes.toString().split('.')[1].length > 2 ? calRes.toFixed(2) : calRes
        } else {
          this.winPercentage = calRes
        }
      }
    },
    predData: {
      get () {
        return this.predictData
      },
      set (data) {
        const orgData = JSON.parse(JSON.stringify(data))
        let winNum = 0
        orgData.map((item) => {
          let compareRes = ''
          if (item.code && item.predict_code) {
            item.code = item.code.replaceAll(',', ' ')
            switch (this.params.param1) {
              case '1ball':
              case 'DD': {
                compareRes = this.predictedSingleBall(item.code, item.predict_code)
                break
              }
              case '2ball':
              case '3ball':
              case '4ball':
              case '5ball': {
                const type = (this.params.param2.indexOf('pre') > -1 || this.params.param2 === 'dual') ? 'pre' : (this.params.param2.indexOf('post') > -1) ? 'post' : 'mid'
                const num = parseInt(this.params.param1.substring(0, 1))
                if (this.params.param2.indexOf('single') > -1) {
                  compareRes = this.predictedGroupBallRange(item.code, item.predict_code, type, num)
                } else {
                  compareRes = this.predictedSingleBallRange(item.code, item.predict_code, type, num)
                }
                break
              }
              case 'BS':
              case 'OE':
              case 'DT': {
                let digit1 = 0
                let digit2 = 0
                let hasEqual = false
                const codeArr = item.code.split(' ').map((n) => { return parseInt(n) })
                if (this.params.param1 === 'DT') {
                  const digits = this.params.param2.split('v')
                  digits.map((n) => { return parseInt(n) - 1 })
                  if (this.lotType === 'pk10' || this.lotType === 'kl10') {
                    digit1 = codeArr[digits[0] - 1]
                    digit2 = codeArr[digits[1] - 1]
                  } else {
                    const dIdx = codeArr.length - digits[0]
                    const tIdx = codeArr.length - digits[1]
                    digit1 = codeArr[dIdx]
                    digit2 = codeArr[tIdx]
                  }
                } else if (this.params.param1 === 'BS') {
                  digit1 = codeArr.reduce((a, b) => {
                    return a + b
                  }, 0)
                  switch (this.lotType) {
                    case 'ssff':
                      digit2 = 22
                      break
                    case 'pk10': {
                      digit1 = codeArr[0] + codeArr[1]
                      digit2 = 11
                      break
                    }
                    case 'shssl':
                      digit2 = 13
                      break
                    case '11c5':
                      digit2 = 30
                      break
                    case 'k3': {
                      digit2 = 10
                      break
                    }
                    case 'kl10': {
                      digit1 = codeArr.reduce((a, b) => { return a + b }, 0).toString()
                      digit2 = 4
                      hasEqual = true
                      break
                    }
                    default:
                      break
                  }
                } else {
                  switch (this.lotType) {
                    case 'pk10':
                    case 'kl10': {
                      digit1 = codeArr[0] + codeArr[1]
                      break
                    }
                    default: {
                      digit1 = codeArr.reduce((a, b) => {
                        return a + b
                      }, 0)
                      break
                    }
                  }
                }
                compareRes = this.predictedOne(digit1, digit2, item.predict_code, hasEqual)
                break
              }
              default:
                break
            }
          }
          item.result = item.predict_code && item.code.length > 0 ? (compareRes !== '' ? '<span class="predict_res_win">中</span>' : '<span>不中</span>') : ''
          if (compareRes !== '') {
            winNum += 1
          }
          item.win_code = compareRes !== '' ? compareRes : ''
          if (this.needFillDigit.includes(this.lotType)) {
            item.code = this.fillCodes(item.code)
            if (this.params.param2 !== 'sum' && this.params.param2 !== '1and2sum') {
              item.win_code = this.fillCodes(item.win_code)
            }
            if (!this.expectFillType.includes(this.params.param1)) {
              item.predict_code = this.fillCodes(item.predict_code)
            }
          }
        })
        this.winningPercent = winNum
        orgData.splice(0, 0, this.nextPredData)
        this.predictData.items = orgData
        if (this.params.param2 === 'sum' || this.params.param2 === '1and2sum') {
          this.predictData.fields[4].label = '和值'
        } else {
          this.predictData.fields[4].label = isMobile ? '中奖号' : '中奖号码'
        }
      }
    },
    isMobile: {
      get () {
        return isMobile()
      }
    }
  },
  methods: {
    getPredictData () {
      this.params.code = this.lotId
      this.predictData.items = []
      this.tableShowText = this.predData.message.loading
      this.$emit('handleUpdateStatus', false)
      return getLotWinNumPredict(this.params)
        .then(res => {
          if (res.status === resStatus.OK) {
            if (res.data.length > 0) {
              this.predData = res.data
              this.setNewestIssue(res.data[0].issue, res.next_predict_code)
            } else {
              this.tableShowText = this.predData.message.empty
            }
          } else {
            this.tableShowText = this.predData.message.error
          }
        })
    },
    setNewestIssue (issue, nextCode) {
      let nextIssue = ''
      if (issue.indexOf('-') > -1) {
        const issueArr = issue.split('-')
        const numLength = issueArr[1].length
        issueArr[1] = fillDigit(parseInt(issueArr[1]) + 1, numLength, 0)
        nextIssue = issueArr.join('-')
      } else {
        nextIssue = parseInt(issue) + 1
      }
      this.nextPredData.issue = nextIssue
      this.nextPredData.predict_code = ''
      // predict_code
      if (
        this.needFillDigit.includes(this.lotType) &&
        !this.expectFillType.includes(this.params.param1)
      ) {
        if (nextCode) {
          this.nextPredData.predict_code = this.fillCodes(nextCode)
        }
      } else {
        this.nextPredData.predict_code = nextCode
      }
      this.nextPredData.result = '<span class="predict_res_wait">等待开奖中</span>'
    },
    fillCodes (data) {
      if (data !== 0 && data !== '' && data !== []) {
        if (typeof data === 'object') {
          return data.map((n) => {
            if (n > 0) {
              return fillDigit(n, 2, 0)
            } else {
              return n
            }
          })
        } else if (typeof data === 'string') {
          if (data.indexOf(',') > -1) {
            return data.split(',').map((item) => {
              return item.split(' ').map((n) => { return fillDigit(n, 2, 0) }).join(' ')
            }).join(',')
          } else {
            return data.split(' ').map((n) => { return fillDigit(n, 2, 0) }).join(' ')
          }
        } else {
          return fillDigit(data, 2, 0)
        }
      } else {
        return data
      }
    },
    showOptions () {
      if (!optionVal[this.lotType]) return
      this.getSeriseOpt()
      this.getTypeOpts()
      this.getNumOpts()
      this.getPredictData()
    },
    getSeriseOpt (isChange = false) {
      const keys = Object.keys(optionVal[this.lotType])
      this.seriseOpts = keys.map((key) => {
        var obj = {}
        obj.value = key
        obj.text = optionName[this.lotType][key]
        return obj
      })
      if (this.params.param1 === '') {
        this.params.param1 = this.seriseOpts[0].value
      }
      if (isChange) { this.getTypeOpts(true) }
    },
    getTypeOpts (isChange = false) {
      const keys = Object.keys(optionVal[this.lotType][this.params.param1])
      this.typeOpts = keys.map((key) => {
        var obj = {}
        const combineKey = `${this.params.param1}_${key}`
        obj.value = key
        obj.text = optionName[this.lotType][combineKey]
        return obj
      })
      this.params.param2 = this.typeOpts[0].value
      if (isChange) { this.getNumOpts() }
    },
    getNumOpts () {
      const numlist = optionVal[this.lotType][this.params.param1][this.params.param2]
      this.numsOpts = numlist.map((key) => {
        var obj = {}
        const unit = key < 13 ? '码' : '组'
        obj.value = key
        obj.text = `${key}${unit}`
        return obj
      })
      this.params.param3 = this.numsOpts[0].value
    },
    predictedSingleBall (code, predictCode) {
      const predictCodes = predictCode ? predictCode.split(' ') : []
      const drawCodes = code ? code.split(' ') : []
      let compareAim = ''
      if (this.lotType === 'k3') {
        const sum = drawCodes.reduce(function (a, b) {
          return parseInt(a) + parseInt(b)
        }, 0)
        compareAim = sum.toString()
      } else {
        const getNum = drawCodes.length - parseInt(this.params.param2.replace('P', ''))
        compareAim = drawCodes[getNum]
      }
      let checker = predictCodes.filter((i) => {
        return parseInt(compareAim) === parseInt(i)
      })
      if (this.needFillDigit.includes(this.lotType)) {
        checker = this.fillCodes(checker)
      }
      return checker.length > 0 ? checker.join() : ''
    },
    predictedGroupBallRange (code, predictCode, type, num) {
      let compareAim = ''
      const codeArr = code.split(' ').map((n) => { return parseInt(n) })
      if (type === 'pre') {
        compareAim = codeArr.slice(0, num).join(' ')
      } else if (type === 'post') {
        compareAim = codeArr.slice(0 - num).join(' ')
      } else {
        codeArr.shift()
        codeArr.pop()
        compareAim = codeArr.join(' ')
      }
      const checker = predictCode.split(',').includes(compareAim)
      return checker ? fillDigit(compareAim, 2, 0) : ''
    },
    predictedSingleBallRange (code, predictCode, type, num) {
      let compareAim = []
      const pCode = predictCode.split(' ').map((n) => { return fillDigit(n, 2, 0) })
      const codeArr = code.split(' ')
      if (type === 'pre') {
        compareAim = codeArr.slice(0, num)
      } else if (type === 'post') {
        compareAim = codeArr.slice(0 - num)
      } else {
        codeArr.shift()
        codeArr.pop()
        compareAim = codeArr
      }
      const checker = compareAim.every((n) => {
        return pCode.includes(fillDigit(n, 2, 0))
      })
      if (this.needFillDigit.includes(this.lotType)) {
        compareAim = this.fillCodes(compareAim)
      }
      return checker ? compareAim.join(' ') : ''
    },
    predictedOne (d, t, pCode, hasEqual) {
      let compareRes = ''
      if (this.params.param1 === 'DT') {
        compareRes = d > t ? '龙' : d === t ? '和' : '虎'
        return compareRes === pCode ? `${d}-${t}` : ''
      } else if (this.params.param1 === 'BS') {
        if (this.lotType === 'kl10') {
          compareRes = parseInt(d.split('')[d.length - 1]) > t ? '大' : '小'
        } else {
          compareRes = hasEqual ? (d > t ? '大' : d === t ? '和' : '小') : (d > t ? '大' : '小')
        }
        return compareRes === pCode ? d : ''
      } else {
        compareRes = d % 2 === 0 ? '双' : '单'
        return compareRes === pCode ? d : ''
      }
    }
  },
  created () {
    this.showOptions()
  },
  watch: {
    updateStatus () {
      this.getPredictData()
    }
  }
}
</script>

<style lang="scss" scoped>
  $colorRed: #EB5757;
  $colorBlue: #2F84ED;
  .top-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    background-color: #FFF;
    .analysis-title {
      flex: 1;
    }
    .analysis-selector{
      flex: 2;
      flex-grow: 5;
      -webkit-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
    .predict-btn{
      background-position: center;
      transition: background 0.5s;
      background: #579FFF;
      background-image: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
      border: none;
      // &:hover{
      //   background: #56CCF2;
      // }
      &:hover {
        background: #579FFF radial-gradient(circle, transparent 1%, #579FFF 1%) center/15000%;
      }
      &:active {
        background-image: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
        background-size: 100%;
        transition: background 0s;
      }
      &:focus, &:active:focus{
        box-shadow: none;
      }
    }
    .predict-percentage{
      align-self: flex-end;
      color: $colorBlue;
      & span{color: $colorRed;}
    }
  }
  ::v-deep .predict-table{
    background-color: #FFF;
    .table thead th{
      font-weight: normal;
      text-align: center;
    }
    .predict_res_win{color: $colorRed;}
    .predict_res_wait{color: $colorBlue;}
  }
  ::v-deep .tooltip-inner{
    overflow-y: scroll!important;
    text-align: left!important;
    background-color: #2e2e2e;
  }
  ::v-deep .arrow{display: none;}
  @media (max-width: 575.98px) {
    .top-bar {
      display: block;
      padding: 15px 15px 20px;
      margin-bottom: 10px;
      .analysis-title {
        display: inline-block;
        width: fit-content;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 10px;
        margin-right: 5px;
      }
      .analysis-selector{
        display: flex;
        flex-wrap: wrap;
        .analysis-select {
          width: calc((100% - 24px)/3);
          margin: 0 6px;
          color: #828282;
          &:nth-child(1) {margin-left: 0;}
          &:nth-child(3) {margin-right: 0;}
        }
        .predict-btn{
          width: 100%;
          min-width: 100%;
          padding: 7px 10px;
          margin: 15px 0 5px;
        }
      }
      .predict-percentage{
        display: inline-block;
        width: fit-content;
        font-size: 13px;
        text-align: left;
      }
    }
    .predict-table {
      margin: 0 auto;
      font-size: 13px;
      overflow-x: scroll;
    }
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .top-bar {
      display: block;
      padding: 15px 15px 20px;
      margin-bottom: 10px;
      .analysis-title {
        display: inline-block;
        width: fit-content;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 10px;
        margin-right: 5px;
      }
      .analysis-selector{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .analysis-select {
          width: calc((100% - 36px)/4);
          margin: 0 6px;
          color: #828282;
          &:nth-child(1) {margin-left: 0;}
        }
        .predict-btn{
          width: calc((100% - 36px)/4);
          min-width: 4em;
          padding: 7px 10px;
          margin: 0 0 0 6px;
        }
      }
      .predict-percentage{
        display: inline-block;
        width: fit-content;
        font-size: 13px;
        text-align: left;
      }
    }
    .predict-table {
      margin: 0 auto;
      font-size: 13px;
      overflow-x: scroll;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .top-bar {
      padding: 10px 25px 20px;
      .analysis-title {
        font-size: 20px;
      }
      .analysis-select {
        width: 130px;
        margin: 0 5px;
      }
      .predict-btn{
        padding: 7px 10px;
        margin: 0 5px;
      }
      .predict-percentage{
        font-size: 13px;
      }
    }
    .predict-table {
      padding: 0 25px;
      margin: 0 auto;
      font-size: 14px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .top-bar {
      padding: 10px 40px 20px;
      .analysis-title {
        font-size: 22px;
      }
      .analysis-select {
        width: 180px;
        margin: 0 5px;
      }
      .predict-btn{
        padding: 7px 10px;
        margin: 0 5px;
      }
      .predict-percentage{
        font-size: 14px;
      }
    }
    .predict-table {
      padding: 0 40px;
      margin: 0 auto;
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) {
    .top-bar {
      padding: 10px 40px 20px;
      .analysis-title {
        font-size: 22px;
      }
      .analysis-select {
        width: 180px;
        margin: 0 5px;
      }
      .predict-btn{
        padding: 7px 10px;
        margin: 0 5px;
      }
      .predict-percentage{
        font-size: 14px;
      }
    }
    .predict-table {
      padding: 0 40px;
      margin: 0 auto;
      font-size: 14px;
    }
  }
  ::v-deep .predict-table{
    .b-table-sticky-header { // 手機尺寸才有header置頂效果
      max-height: calc(100vh - 250px);
      margin-bottom: 10px;
      box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.03);
    }
    .table td {cursor: default;}
    @media (max-width: 575.98px) {
      .table {
        width: max-content;
        min-width: 100%;
        & thead{
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
          & th {
            font-size: 14px;
            font-weight: 500;
            line-height: 23px;
            color: #4F4F4F;
            background-color: #FFF;
            border-color: #FFF;
            padding: 10px 8px;
          }
        }
        & tbody {
          font-size: 13px;
          color: #4F4F4F;
          & td {
            padding: 15px 10px;
            border: 1px solid #E0E0E0;
          }
        }
      }
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .table {
        width: max-content;
        min-width: 100%;
        & thead{
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
          & th {
            font-size: 13px;
            font-weight: 500;
            line-height: 23px;
            color: #4F4F4F;
            background-color: #FFF;
            border-color: #FFF;
            padding: 10px 8px;
          }
        }
        & tbody {
          font-size: 12px;
          color: #4F4F4F;
          & td {
            padding: 15px 10px;
            border: 1px solid #E0E0E0;
          }
        }
      }
    }
    @media (min-width: 768px) and (max-width: 991.98px) {}
    @media (min-width: 992px) and (max-width: 1199.98px) {}
    @media (min-width: 1200px) {
      .table thead{
        & th {font-size: 16px;}
      }
      .fixed-width{width: 100%;}
    }
  }
  ::v-deep .tooltip-inner{
    @media (max-width: 575.98px) {}
    @media (min-width: 576px) and (max-width: 767.98px) {}
    @media (min-width: 768px) and (max-width: 991.98px) {
      max-width: 360px!important;
      max-height: 250px!important;
      padding: 10px 15px!important;
    }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      max-width: 400px!important;
      max-height: 250px!important;
      padding: 10px 15px!important;
    }
    @media (min-width: 1200px) {
      max-width: 400px!important;
      max-height: 250px!important;
      padding: 10px 15px!important;
    }
  }
</style>
