<template>
  <div class="content-container">
    <div class="head-container">
      <div class="title-container">
        <h2>基本走势</h2>
        <b-form-group>
          <b-form-radio-group
            v-model="queryPageSize"
            :options="pageSizeList"
            buttons
            button-variant="outline-primary"
            size="sm"
            name="radios-btn-query" />
        </b-form-group>
      </div>
      <div class="option-container">
        <b-form-checkbox
          size="sm"
          v-model="leaksStatus"
          name="leaks-status">
          遗漏
        </b-form-checkbox>
        <b-form-checkbox
          size="sm"
          v-model="leaksLevelStatus"
          name="leaks-level-status">
          遗漏分层
        </b-form-checkbox>
        <b-form-checkbox
          size="sm"
          v-model="showLineStatus"
          name="show-line-status">
          折线图
        </b-form-checkbox>
      </div>
    </div>
    <div class="main-container">
      <Table
        :updateStatus="updateStatus"
        :leaksStatus="leaksStatus"
        :leaksLevelStatus="leaksLevelStatus"
        :showLineStatus="showLineStatus"
        :pageSize="queryPageSize"
        :headArr="headArr"
        :resultMinNum="resultMinNum"
        :resultMaxNum="resultMaxNum" />
    </div>
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'
import Table from './Table'
import { getLotInfoById } from '@/utils/lot'
export default {
  components: {
    Table
  },
  props: {
    updateStatus: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      leaksStatus: true,
      leaksLevelStatus: false,
      showLineStatus: true,
      queryPageSize: 50,
      pageSizeList: [
        { text: '最近50期', value: 50 },
        { text: '最近100期', value: 100 }
      ]
    }
  },
  computed: {
    lotId: {
      get () {
        if (!this.$route.query.id) {
          return allLots[0].children[0].id
        }
        return this.$route.query.id
      }
    },
    lotInfo: {
      get () {
        // const result = allLots
        //   // find tab
        //   .filter(item => {
        //     return (
        //       item.children
        //         .filter(lot => {
        //           return lot.id === this.lotId
        //         })
        //         .length !== 0
        //     )
        //   })[0]
        //   .children
        //   // find lot
        //   .filter(lot => {
        //     return lot.id === this.lotId
        //   })[0]
        return getLotInfoById(this.lotId)
      }
    },
    chartInfo: {
      get () {
        return this.lotInfo.rule.chart
      }
    },
    headArr: {
      get () {
        return this.chartInfo.headArr
      }
    },
    resultMinNum: {
      get () {
        return this.chartInfo.resultMinNum
      }
    },
    resultMaxNum: {
      get () {
        return this.chartInfo.resultMaxNum
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  // background-color: #FFF;
  .head-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 20px;
    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 30px;
      h2 {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
      .form-group {
        margin: 0 0 5px 0;
      }
    }
    .option-container {
      display: flex;
      align-items: center;
      border-top: 1px solid #dee2e6;
      height: 70px;
      padding-left: 30px;
      // gap: 15px;
      & > div {margin-right: 20px;}
    }
  }
  .main-container {
    padding: 0 15px 10px;
  }
}
@media (max-width: 575.98px) {
  .content-container {
    margin-bottom: 10px;
    .head-container {
      background-color: #FFF;
      padding-top: 5px;
      margin-bottom: 10px;
      .title-container {
        padding: 0 15px;
        h2 {
          height: fit-content;
          line-height: 23px;
          font-size: 14px;
          font-weight: 400;
          padding: 20px 0 10px;
        }
        .form-group {
          margin: 5px 0 0;
        }
      }
      .option-container {
        display: flex;
        align-items: center;
        border-top: 1px solid #dee2e6;
        height: 60px;
        padding-left: 15px;
        padding-top: 5px;
      }
    }
    .main-container {
      background-color: #FFF;
      padding: 0;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .content-container {
    margin-bottom: 10px;
    .head-container {
      background-color: #FFF;
      padding-top: 0;
      margin-bottom: 10px;
      .title-container {
        padding: 0 15px;
        h2 {
          height: fit-content;
          line-height: 23px;
          font-size: 14px;
          font-weight: 400;
          padding: 20px 0 10px;
        }
        .form-group {
          margin: 5px 0 0;
        }
      }
      .option-container {
        display: flex;
        align-items: center;
        border-top: 1px solid #dee2e6;
        height: 60px;
        padding-left: 15px;
        padding-top: 5px;
      }
    }
    .main-container {
      background-color: #FFF;
      padding: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .content-container {
    .head-container {
      .title-container {
        padding: 0 20px;
        h2 {
          font-size: 18px;
        }
        .form-group {
          margin: 0 0 5px 0;
        }
      }
      .option-container {
        height: 60px;
        padding-left: 20px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {
  .content-container {
    .head-container {
      .title-container {
        padding: 0 30px;
        .form-group {
          margin: 0 0 8px 0;
        }
      }
      .option-container {
        padding-left: 30px;
      }
    }
  }
}
</style>
