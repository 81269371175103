<template>
  <div class="result-container">
    <div class="next-issue">
      距<span>{{ isMobile ? '下' : `  ${lotNextIssue} `}}</span>期开奖
      <span>{{ isMobile ? '' : '仅有'}}</span>
    </div>
    <div
      v-if="lotStatus === 'close'"
      class="next-time-not-yet">等待开奖</div>
    <div
      v-if="lotStatus !== 'close'"
      class="next-time">
      <div>{{ lotNextHr }}</div>
      <span>{{isMobile ? ':' : '時'}}</span>
      <div>{{ lotNextMin }}</div>
      <span>{{isMobile ? ':' : '分'}}</span>
      <div>{{ lotNextSec }}</div>
      <span>{{isMobile ? '' : '秒'}}</span>
    </div>
    <div class="all-issue">已开<span>{{ lotNowIssue }}</span>期，还剩<span>{{ lotTotalIssue - lotNowIssue }}</span>期</div>
  </div>
</template>

<script>
import { fillDigit } from '@/utils/string'
import { isMobile } from "@/utils/device"

export default {
  data () {
    return {}
  },
  props: {
    lotData: {
      default: null,
      type: Object
    },
    lotStatus: {
      default: 'open',
      type: String
    },
    lotNextDate: null,
    dateNow: null
  },
  computed: {
    isMobile: {
      get () {
        return isMobile ()
      }
    },
    lotLottery: {
      get () {
        if (!this.lotData) {
          return null
        }
        return this.lotData.lottery
      }
    },
    lotLastIssue: {
      get () {
        if (!this.lotLottery) {
          return ''
        }
        return this.lotLottery.last_issue
      }
    },
    lotNextIssue: {
      get () {
        if (!this.lotLottery) {
          return ''
        }
        return this.lotLottery.next_issue
      }
    },
    lotNextHr: {
      get () {
        const hr = 1000 * 60 * 60
        if (!this.lotNextDate || !this.dateNow) {
          return '00'
        }
        const diff = this.lotNextDate - this.dateNow
        return diff < 0 ? '00' : fillDigit(Math.floor(diff / hr), 2, '0')
      }
    },
    lotNextMin: {
      get () {
        const min = 1000 * 60
        const basic = 60
        if (!this.lotNextDate || !this.dateNow) {
          return '00'
        }
        const diff = this.lotNextDate - this.dateNow
        return diff < 0 ? '00' : fillDigit(Math.floor(diff / min % basic), 2, '0')
      }
    },
    lotNextSec: {
      get () {
        const sec = 1000
        const basic = 60
        if (!this.lotNextDate || !this.dateNow) {
          return '00'
        }
        const diff = this.lotNextDate - this.dateNow
        return diff < 0 ? '00' : fillDigit(Math.floor(diff / sec % basic), 2, '0')
      }
    },
    lotNowIssue: {
      get () {
        if (!this.lotLottery || !this.lotLottery.now_issue) {
          return '0'
        }
        return this.lotLottery.now_issue
      }
    },
    lotTotalIssue: {
      get () {
        if (!this.lotLottery) {
          return 0
        }
        return this.lotLottery.total_issue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.result-container {
  display: flex;
  width: fit-content;
  min-width: max-content;
  flex-direction: column;
  align-items: self-start;
  box-sizing: border-box;
  padding-left: 40px;
  margin: 70px 0 0;
  // gap: 20px;
  & > div {
    margin-bottom: 20px;
    &:last-child {margin-bottom: 0;}
  }
  .next-issue {
    font-size: 18px;
    color: #333333;
    span {
      color: #EB5757;
    }
  }
  .next-time-not-yet {
    display: flex;
    height: 33px;
    font-size: 13px;
    border-radius: 3px;
    padding: 0 10px;
    color: #F2C94C;
    background-color: #4F4F4F;
    align-items: center;
  }
  .next-time {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #828282;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 33px;
      font-size: 13px;
      color: #F2C94C;
      background-color: #4F4F4F;
      margin: 0 5px;
      border-radius: 3px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      &:first-child {margin-left: 0;}
    }
  }
  .all-issue {
    font-size: 16px;
    color: #828282;
    span {
      color: #EB5757;
      padding: 0 6px;
    }
  }
}
@media (max-width: 575.98px) {
  .result-container {
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    padding: 0;
    margin: 0;
    & > div {margin-bottom: 0;}
    .next-issue {
      font-size: 13px;
      color: #4F4F4F;
      span {
        color: #4F4F4F;
      }
    }
    .next-time-not-yet {
      height: fit-content;
      font-size: 13px;
      line-height: 18px;
      padding: 0 3px;
      font-weight: 500;
      color: #EB5757;
      background-color: transparent;
    }
    .next-time {
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
      color: #4F4F4F;
      div {
        width: fit-content;
        height: fit-content;
        font-size: 13px;
        background-color: transparent;
        margin: 0;
        &:first-child {margin-left: 3px;}
      }
      > * {color: #EB5757!important;}
    }
    .all-issue {
      font-size: 13px;
      color: #4F4F4F;
      margin-left: 10px;
      span {
        padding: 0;
        font-weight: 500;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .result-container {
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    padding: 0;
    margin: 0;
    & > div {margin-bottom: 0;}
    .next-issue {
      font-size: 13px;
      color: #4F4F4F;
      span {
        color: #4F4F4F;
      }
    }
    .next-time-not-yet {
      height: fit-content;
      font-size: 13px;
      line-height: 18px;
      padding: 0 3px;
      font-weight: 500;
      color: #EB5757;
      background-color: transparent;
    }
    .next-time {
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
      color: #4F4F4F;
      div {
        width: fit-content;
        height: fit-content;
        font-size: 13px;
        background-color: transparent;
        margin: 0;
        &:first-child {margin-left: 3px;}
      }
      > * {color: #EB5757!important;}
    }
    .all-issue {
      font-size: 13px;
      color: #4F4F4F;
      margin-left: 10px;
      span {
        padding: 0;
        font-weight: 500;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .result-container {
    padding: 0 20px 0 40px;
    & > div {margin-bottom: 15px;}
    .next-issue {font-size: 16px;}
    .next-time {font-size: 16px;}
    .all-issue {font-size: 14px;}
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .result-container {
    padding: 0 25px 0 40px;
  }
}
@media (min-width: 1200px) {
  .result-container {
    width: 330px;
    min-width: 330px;
  }
}
</style>