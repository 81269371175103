<template>
  <div class="head-container">
    <div class="title-container">
      <h2>和值走势</h2>
      <b-form-group>
        <b-form-radio-group
          v-model="queryPageSize"
          :options="pageSizeList"
          buttons
          button-variant="outline-primary"
          size="sm"
          name="radios-btn-query" />
      </b-form-group>
    </div>
    <div class="option-container">
      <div class="filter-options">
        <h3>标注选择</h3>
        <b-form-checkbox
          size="sm"
          v-model="lStatus"
          name="leaks-status">
          遗漏
        </b-form-checkbox>
        <b-form-checkbox
          size="sm"
          v-model="llStatus"
          name="leaks-level-status">
          遗漏分层
        </b-form-checkbox>
        <b-form-checkbox
          size="sm"
          v-model="slStatus"
          name="show-line-status">
          折线图
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { getDiffDay, dateFormat } from '@/utils/time'

export default {
  props: {
    pageSize: {
      type: [Number, String],
      required: true
    },
    leaksStatus: {
      type: Boolean,
      required: true
    },
    leaksLevelStatus: {
      type: Boolean,
      required: true
    },
    showLineStatus: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      pageSizeList: [
        { text: '今天', value: this.getQuickDate(0) },
        { text: '昨天', value: this.getQuickDate(-1) },
        { text: '前天', value: this.getQuickDate(-2) },
        { text: '最近50期', value: 50 },
        { text: '最近100期', value: 100 }
      ]
    }
  },
  computed: {
    queryPageSize: {
      get () {
        return this.pageSize
      },
      set (num) {
        this.$emit('handleChangePageSize', num)
      }
    },
    lStatus: {
      get () {
        return this.leaksStatus
      },
      set (boo) {
        this.$emit('handleLeaksStatus', boo)
      }
    },
    llStatus: {
      get () {
        return this.leaksLevelStatus
      },
      set (boo) {
        this.$emit('handleLeaksLevelStatus', boo)
      }
    },
    slStatus: {
      get () {
        return this.showLineStatus
      },
      set (boo) {
        this.$emit('handleShowLineStatus', boo)
      }
    }
  },
  methods: {
    today () {
      return new Date()
    },
    getQuickDate (diffDay) {
      const dayMS = 1000 * 24 * 60 * 60
      const yyyyMMdd = dateFormat('yyyy-MM-dd', getDiffDay(diffDay * dayMS))
      return yyyyMMdd
    }
  }
}
</script>

<style lang="scss" scoped>
.head-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .form-group {
      margin: 0 20px 0 0;
    }
    h2 {
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      padding-left: 20px;
    }
  }
  .option-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid #dee2e6;
    padding: 20px 30px;
    // gap: 10px;
    font-size: 14px;
    .filter-options {
      display: flex;
      justify-content: center;
      align-items: center;
      // gap: 15px;
      & > h3 {margin-right: 20px;}
      & > div {margin-right: 20px;}
    }
  }
}
@media (max-width: 575.98px) {
  .head-container {
    background-color: #FFF;
    padding-top: 5px;
    margin-bottom: 10px;
    .title-container {
      flex-wrap: wrap;
      padding: 15px;
      .form-group {
        margin: 0;
      }
      h2 {
        height: fit-content;
        line-height: 23px;
        font-size: 14px;
        font-weight: 400;
        padding: 0;
        margin-bottom: 10px;
      }
    }
    .option-container {
      padding: 15px 15px 5px;
      // gap: 10px;
      font-size: 14px;
      .filter-options {
        justify-content: flex-start;
        flex-wrap: wrap;
        // gap: 15px;
        & > h3 {
          line-height: 23px;
          margin-bottom: 10px;
          font-weight: 400;
        }
        & > div {margin-bottom: 10px;}
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .head-container {
    background-color: #FFF;
    padding-top: 5px;
    margin-bottom: 10px;
    .title-container {
      flex-wrap: wrap;
      padding: 15px;
      .form-group {
        margin: 0;
      }
      h2 {
        height: fit-content;
        line-height: 23px;
        font-size: 14px;
        font-weight: 400;
        padding: 0;
        margin-bottom: 10px;
      }
    }
    .option-container {
      padding: 15px 15px 5px;
      // gap: 10px;
      font-size: 14px;
      .filter-options {
        justify-content: flex-start;
        flex-wrap: wrap;
        // gap: 15px;
        & > h3 {
          line-height: 23px;
          margin-bottom: 10px;
          font-weight: 400;
        }
        & > div {margin-bottom: 10px;}
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
