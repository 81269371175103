export const lottoApiType = {
  ssff: [
    'CQSSC', 'XJSSC', 'TJSSC', 'TG300', 'TG60', 'YN300', 'XG300', 'YN60', 'XG60',
    'HN300', 'HN60', 'RB60', 'QQFFC', 'TXFFC', 'TX2FCS', 'CQSSC30S', 'TXFFC30S',
    'TX2FCD', 'TX5FC', 'QIQFFC', 'JND30S', 'CQ5FC', 'CQFFC', 'JDCQSSC', 'QQ30S'],
  '11c5': [
    'GD11X5', 'SD11X5', 'JX11X5', 'SH11X5', 'AF11X5', 'HLJ11X5', 'YN11X5', 'HB11X5',
    'TG11X5', 'JS11X5', 'LL11X5', 'GX11X5', 'JND11X5'
  ],
  kl10: ['CQXYNC', 'GDKL10', 'TJKL10', 'GXKL10', 'HNKL10', 'SCKL12'],
  pk10: ['XIYFT', 'BJPK10', 'XGPK10', 'XYFT', 'MCPK10', 'TXPK10'],
  k3: ['JSKS', 'HLK3', 'HBK3', 'JLK3', 'AHK3', 'GXKS'],
  shssl: ['SHSSL'],
  chk3: ['FC3D', 'TCPL3', 'JND3D'],
  chk5: ['TCPL5']
}

export const analysisOptionValue = {
  ssff: {
    '1ball': {
      P5: [5, 6, 7, 8, 9],
      P4: [5, 6, 7, 8, 9],
      P3: [5, 6, 7, 8, 9],
      P2: [5, 6, 7, 8, 9],
      P1: [5, 6, 7, 8, 9]
    },
    '2ball': {
      'pre2-single': [50, 60, 70, 80],
      'pre2-dual': [7, 8, 9],
      'post2-single': [50, 60, 70, 80],
      'post2-dual': [7, 8, 9]
    },
    '3ball': {
      'pre3-single': [400, 600, 800, 900],
      'pre3-dual': [7, 8, 9],
      'middle3-single': [400, 600, 800, 900],
      'middle3-dual': [7, 8, 9],
      'post3-single': [400, 600, 800, 900],
      'post3-dual': [7, 8, 9]
    },
    '4ball': {
      'pre4-dual': [7, 8, 9],
      'post4-dual': [7, 8, 9]
    },
    '5ball': { dual: [7, 8, 9] },
    DT: { '5v4': [1], '5v3': [1], '5v2': [1], '5v1': [1], '4v3': [1], '4v2': [1], '4v1': [1] },
    BS: { sum: [1] }
  },
  '11c5': {
    '1ball': {
      P5: [5, 6, 7, 8, 9],
      P4: [5, 6, 7, 8, 9],
      P3: [5, 6, 7, 8, 9],
      P2: [5, 6, 7, 8, 9],
      P1: [5, 6, 7, 8, 9]
    },
    '2ball': {
      'pre2-single': [50, 60, 70, 80],
      'pre2-dual': [7, 8, 9],
      'post2-single': [50, 60, 70, 80],
      'post2-dual': [7, 8, 9]
    },
    '3ball': {
      'pre3-single': [400, 600, 800, 900],
      'pre3-dual': [7, 8, 9],
      'middle3-single': [400, 600, 800, 900],
      'middle3-dual': [7, 8, 9],
      'post3-single': [400, 600, 800, 900],
      'post3-dual': [7, 8, 9]
    },
    '4ball': {
      'pre4-dual': [7, 8, 9],
      'post4-dual': [7, 8, 9]
    },
    '5ball': { dual: [7, 8, 9] }
  },
  kl10: {
    DD: {
      P8: [5, 6, 7, 8, 9],
      P7: [5, 6, 7, 8, 9],
      P6: [5, 6, 7, 8, 9],
      P5: [5, 6, 7, 8, 9],
      P4: [5, 6, 7, 8, 9],
      P3: [5, 6, 7, 8, 9],
      P2: [5, 6, 7, 8, 9]
    },
    DT: { '1v8': [1], '2v7': [1], '3v6': [1], '4v5': [1] },
    BS: { '1and2sum': [1] },
    OE: { '1and2sum': [1] }
  },
  pk10: {
    DD: {
      P10: [5, 6, 7, 8, 9],
      P9: [5, 6, 7, 8, 9],
      P8: [5, 6, 7, 8, 9],
      P7: [5, 6, 7, 8, 9],
      P6: [5, 6, 7, 8, 9],
      P5: [5, 6, 7, 8, 9],
      P4: [5, 6, 7, 8, 9]
    },
    DT: { '1v10': [1], '2v9': [1], '3v8': [1], '4v7': [1], '5v6': [1] },
    BS: { '1and2sum': [1] },
    OE: { '1and2sum': [1] }
  },
  k3: {
    '1ball': { sum: [7, 8, 9, 10, 11, 12] },
    BS: { sum: [1] },
    OE: { sum: [1] }
  },
  shssl: {
    // '1ball': ['sum'],
    BS: { sum: [1] },
    OE: { sum: [1] }
  },
  chk3: {
    '1ball': { P3: [5, 6, 7, 8, 9], P2: [5, 6, 7, 8, 9], P1: [5, 6, 7, 8, 9] },
    '2ball': { 'pre2-single': [50, 60, 70, 80], 'pre2-dual': [7, 8, 9], 'post2-single': [50, 60, 70, 80], 'post2-dual': [7, 8, 9] },
    '3ball': { 'pre3-single': [400, 600, 800, 900], 'pre3-dual': [7, 8, 9], 'post3-single': [400, 600, 800, 900], 'post3-dual': [7, 8, 9] },
    BS: { sum: [1] },
    OE: { sum: [1] }
  },
  chk5: {
    '1ball': { P5: [5, 6, 7, 8, 9], P4: [5, 6, 7, 8, 9], P3: [5, 6, 7, 8, 9], P2: [5, 6, 7, 8, 9], P1: [5, 6, 7, 8, 9] },
    '2ball': { 'pre2-single': [50, 60, 70, 80], 'pre2-dual': [7, 8, 9], 'post2-single': [50, 60, 70, 80], 'post2-dual': [7, 8, 9] },
    '3ball': { 'pre3-single': [400, 600, 800, 900], 'pre3-dual': [7, 8, 9], 'middle3-single': [400, 600, 800, 900], 'middle3-dual': [7, 8, 9], 'post3-single': [400, 600, 800, 900], 'post3-dual': [7, 8, 9] },
    '4ball': { 'pre4-dual': [7, 8, 9], 'post4-dual': [7, 8, 9] },
    '5ball': { dual: [7, 8, 9] },
    BS: { sum: [1] },
    OE: { sum: [1] }
  }
}

export const analysisOptionName = {
  ssff: {
    '1ball': '一星系列',
    '2ball': '二星系列',
    '3ball': '三星系列',
    '4ball': '四星系列',
    '5ball': '五星系列',
    DT: '龙虎系列',
    BS: '大小系列',
    '1ball_P5': '定万位',
    '1ball_P4': '定千位',
    '1ball_P3': '定百位',
    '1ball_P2': '定十位',
    '1ball_P1': '定个位',
    '2ball_pre2-single': '前二单式',
    '2ball_pre2-dual': '前二复式',
    '2ball_post2-single': '后二单式',
    '2ball_post2-dual': '后二复式',
    '3ball_pre3-single': '前三单式',
    '3ball_pre3-dual': '前三复式',
    '3ball_middle3-single': '中三单式',
    '3ball_middle3-dual': '中三复式',
    '3ball_post3-single': '后三单式',
    '3ball_post3-dual': '后三复式',
    '4ball_pre4-dual': '前四复式',
    '4ball_post4-dual': '后四复式',
    '5ball_dual': '五星复式',
    DT_5v4: '万千',
    DT_5v3: '万百',
    DT_5v2: '万十',
    DT_5v1: '万个',
    DT_4v3: '千百',
    DT_4v2: '千十',
    DT_4v1: '千个',
    BS_sum: '和值大小'
  },
  '11c5': {
    '1ball': '一星系列',
    '2ball': '二星系列',
    '3ball': '三星系列',
    '4ball': '四星系列',
    '5ball': '五星系列',
    DT: '龙虎系列',
    BS: '大小系列',
    OE: '单双系列',
    '1ball_P5': '定万位',
    '1ball_P4': '定千位',
    '1ball_P3': '定百位',
    '1ball_P2': '定十位',
    '1ball_P1': '定个位',
    '2ball_pre2-single': '前二单式',
    '2ball_pre2-dual': '前二复式',
    '2ball_post2-single': '后二单式',
    '2ball_post2-dual': '后二复式',
    '3ball_pre3-single': '前三单式',
    '3ball_pre3-dual': '前三复式',
    '3ball_middle3-single': '中三单式',
    '3ball_middle3-dual': '中三复式',
    '3ball_post3-single': '后三单式',
    '3ball_post3-dual': '后三复式',
    '4ball_pre4-dual': '前四复式',
    '4ball_post4-dual': '后四复式',
    '5ball_dual': '五星复式'
  },
  kl10: {
    DD: '定位胆系列',
    DT: '龙虎系列',
    BS: '大小系列',
    OE: '单双系列',
    DD_P8: '冠军定码',
    DD_P7: '亚军定码',
    DD_P6: '季军定码',
    DD_P5: '第四名定码',
    DD_P4: '第五名定码',
    DD_P3: '第六名定码',
    DD_P2: '第七名定码',
    DT_1v8: '1v8',
    DT_2v7: '2v7',
    DT_3v6: '3v6',
    DT_4v5: '4v5',
    // BS_1and2sum: '冠亚和大小',
    BS_1and2sum: '总和尾数大小',
    OE_1and2sum: '冠亚和单双'
  },
  pk10: {
    DD: '定位胆系列',
    DT: '龙虎系列',
    BS: '大小系列',
    OE: '单双系列',
    DD_P10: '冠军定码',
    DD_P9: '亚军定码',
    DD_P8: '季军定码',
    DD_P7: '第四名定码',
    DD_P6: '第五名定码',
    DD_P5: '第六名定码',
    DD_P4: '第七名定码',
    DT_1v10: '1v10',
    DT_2v9: '2v9',
    DT_3v8: '3v8',
    DT_4v7: '4v7',
    DT_5v6: '5v6',
    BS_1and2sum: '冠亚和大小',
    OE_1and2sum: '冠亚和单双'
  },
  k3: {
    '1ball': '和值系列',
    BS: '大小系列',
    OE: '单双系列',
    '1ball_sum': '和值定码',
    BS_sum: '和值大小',
    OE_sum: '和值单双'
  },
  shssl: {
    BS: '大小系列',
    OE: '单双系列',
    BS_sum: '和值大小',
    OE_sum: '和值单双'
  },
  chk3: {
    '1ball': '一星系列',
    '2ball': '二星系列',
    '3ball': '三星系列',
    BS: '大小系列',
    OE: '单双系列',
    '1ball_P3': '定百位',
    '1ball_P2': '定十位',
    '1ball_P1': '定个位',
    '2ball_pre2-single': '前二单式',
    '2ball_pre2-dual': '前二复式',
    '2ball_post2-single': '后二单式',
    '2ball_post2-dual': '后二复式',
    '3ball_pre3-single': '前三单式',
    '3ball_pre3-dual': '前三复式',
    BS_sum: '和值大小',
    OE_sum: '和值单双'
  },
  chk5: {
    '1ball': '一星系列',
    '2ball': '二星系列',
    '3ball': '三星系列',
    '4ball': '四星系列',
    '5ball': '五星系列',
    BS: '大小系列',
    OE: '单双系列',
    '1ball_P5': '定万位',
    '1ball_P4': '定千位',
    '1ball_P3': '定百位',
    '1ball_P2': '定十位',
    '1ball_P1': '定个位',
    '2ball_pre2-single': '前二单式',
    '2ball_pre2-dual': '前二复式',
    '2ball_post2-single': '后二单式',
    '2ball_post2-dual': '后二复式',
    '3ball_pre3-single': '前三单式',
    '3ball_pre3-dual': '前三复式',
    '3ball_middle3-single': '中三单式',
    '3ball_middle3-dual': '中三复式',
    '3ball_post3-single': '后三单式',
    '3ball_post3-dual': '后三复式',
    '4ball_pre4-dual': '前四复式',
    '4ball_post4-dual': '后四复式',
    '5ball_dual': '五星复式',
    BS_sum: '和值大小',
    OE_sum: '和值单双'
  }
}
