<template>
  <div
    class="table-container"
    :class="isMobile && tableData ? 'fixed-height' : ''">
    <canvas
      ref="tableCanvas"
      style="position:absolute; top:0; left:0; z-index:99;"
      :width="canvasW"
      :height="canvasH">
    </canvas>
    <!-- sticky-header -->
    <b-table-simple>
      <!-- column group -->
      <!-- 开奖期数 -->
      <colgroup><col></colgroup>
      <!-- 开奖号码 -->
      <colgroup><col></colgroup>
      <!-- 選擇選項 -->
      <colgroup>
        <col
          v-for="(subHead, subIdx) in subHeadSet"
          :key="'set-sub-col-' + subIdx">
      </colgroup>
      <!-- 形态特征 -->
      <colgroup>
        <col
          v-for="(item, idx) in featureList"
          :key="'type-col-' + idx">
      </colgroup>

      <!-- table head -->
      <b-thead>
        <b-tr>
          <!-- 开奖期数 -->
          <b-th
            rowspan="2">
            开奖期数
          </b-th>
          <!-- 开奖号码 -->
          <b-th
            rowspan="2">
            开奖号码
          </b-th>
          <!-- 選擇選項 -->
          <b-th :colspan="subSetLen">
            {{ colOptions[lotCol].text }}
          </b-th>
          <!-- 形态特征 -->
          <b-th colspan="6">
            形态特征
          </b-th>
        </b-tr>
        <b-tr>
          <!-- 選擇選項 -->
          <b-th
            v-for="(subHead, idx) in subHeadSet"
            :key="'sub-head-result-col-' + idx">
            {{ subHead }}
          </b-th>
          <!-- 形态特征 -->
          <b-th
            v-for="(item, idx) in featureList"
            :key="'sub-head-feature-col-' + idx">
            {{ item }}
          </b-th>
        </b-tr>
      </b-thead>

      <!-- table body -->
      <b-tbody ref="tableBody">
        <b-tr v-if="!tableData">
          <b-td
            :colspan="tableColNum"
            :class="showMsg == 'error' ? 'error-msg' : ''">
            {{tableMsg[showMsg]}}
          </b-td>
        </b-tr>
        <b-tr
          v-else
          v-for="(lot, idx) in tableData"
          :key="lot.id">
          <!-- 开奖期数 -->
          <b-td>{{ lot.issue }}</b-td>
          <!-- 开奖号码 -->
          <b-td>
            <div class="result-nums-container">
              <div
                v-for="(num, numIdx) in generateLotNums(lot.code)"
                :key="'lot-num-' + lot.id + numIdx">{{ generateResultNum(num) }}</div>
            </div>
          </b-td>
          <!-- 選擇選項: 號碼 -->
          <b-td
            v-for="(subNum, subIdx) in subHeadSet"
            :key="'row-' + lot.id + '-idx-' + subIdx"
            :class="generateCellClass(lot, idx, subIdx)">
            <!-- v-if="showCellContent(lot, subIdx)" -->
            <div
              class="num-container"
              :class="generateLotNumClass(lot, subIdx)">
              {{ generateCellContent(lot, idx, subIdx) }}
            </div>
            <!-- <div
              v-else
              class="num-container not-lot-num" /> -->
          </b-td>
          <!-- 形态特征 -->
          <b-td
            v-for="(feature, featureIdx) in featureList"
            :key="'row' + lot.id + '-idx-' + featureIdx"
            :class="generateFeatureClass(lot, idx, featureIdx)">
            {{ generateFeatureContent(lot, idx, featureIdx) }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'
import { getLotWinNumHistory } from '@/api/client/lot'
import resStatus from '@/constants/resStatus'
import { getLotInfoById, isOdd, isBig, isPrime } from '@/utils/lot'
import { range, last, findIndex } from 'lodash'
import { fillDigit } from '@/utils/string'
import { isMobile } from '@/utils/device'

export default {
  props: {
    updateStatus: {
      type: Boolean,
      required: true
    },
    selCol: {
      type: String,
      required: true
    },
    leaksStatus: {
      type: Boolean,
      required: true
    },
    leaksLevelStatus: {
      type: Boolean,
      required: true
    },
    showLineStatus: {
      type: Boolean,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      firstLoaded: true,
      tableData: null,
      featureList: ['单', '双', '大', '小', '质', '合'],
      canvasW: 0,
      canvasH: 0,
      canvasDraw: null,
      lotTd: null,
      showMsg: '',
      tableMsg: {
        error: '發生错误，请稍后再试',
        empty: '目前尚无资料',
        loading: '获取资料中...'
      }
    }
  },
  computed: {
    lotId: {
      get () {
        if (!this.$route.query.id) {
          return allLots[0].children[0].id
        }
        return this.$route.query.id
      }
    },
    lotInfo: {
      get () {
        return (
          getLotInfoById(this.lotId)
        )
      }
    },
    query: {
      get () {
        return {
          // page: 1,
          // pageSize: this.pageSize,
          // date: '2021-03-01',
          code: this.lotId,
          latest: this.pageSize
        }
      }
    },
    resultMinNum: {
      get () {
        return this.lotInfo.rule.chart.resultMinNum
      }
    },
    resultMaxNum: {
      get () {
        return this.lotInfo.rule.chart.resultMaxNum
      }
    },
    colOptions: {
      get () {
        return (
          getLotInfoById(this.lotId)
            .rule.chart.headArr
            .map((item, idx) => {
              return {
                text: item,
                value: `col-${idx}`
              }
            })
        )
      }
    },
    subHeadSet: {
      get () {
        return range(this.resultMinNum, this.resultMaxNum + 1)
      }
    },
    tableColNum: {
      get () {
        return this.subSetLen + this.featureList.length + 3
      }
    },
    subSetLen: {
      get () {
        return this.subHeadSet.length
      }
    },
    lotCol: {
      get () {
        return last(this.selCol.split('-'))
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    handleUpdateStatus (boo) {
      this.$emit('handleUpdateStatus', boo)
    },
    initTableData () {
      this.showMsg = 'loading'
      getLotWinNumHistory(this.query)
        .then(res => {
          this.handleUpdateStatus(false)
          if (res.status === resStatus.OK) {
            if (res.data.length === 0) {
              this.showMsg = 'empty'
              this.emptyPageData()
            } else {
              this.tableData = res.data
              this.showMsg = ''
            }
          } else {
            this.showMsg = 'error'
            this.emptyPageData()
          }
        })
    },
    emptyPageData () {
      this.tableData = null
      this.canvasW = 0
      this.canvasH = 0
    },
    generateCellClass (lot, idx, subIdx) {
      const cellClass = `digit-default digit-${this.getSubSetGroup()}`
      const groupNextNumIdx = this.getGroupNextNumIdx(0, subIdx)
      if (
        !this.showLotNum(subIdx, lot) &&
        this.leaksLevelStatus &&
        groupNextNumIdx - idx > 0
      ) {
        return `${cellClass} leaksLevel`
      }
      return `${cellClass} leaksLevel-no`
    },
    getSubSetGroup () {
      return Number(this.lotCol)
    },
    getGroupNextNumIdx (idx, subIdx) {
      const setGroup = this.getSubSetGroup()
      const subIdxNum = subIdx % this.subSetLen + this.resultMinNum
      const nextIdx = findIndex(this.tableData, (i) => {
        return Number(i.code.split(',')[setGroup]) === subIdxNum
      }, idx)
      if (nextIdx === -1) {
        // return this.query.latest
        return this.tableData.length
      }
      return nextIdx
    },
    showLotNum (subIdx, lot) {
      const setGroup = this.getSubSetGroup()
      const lotCodes = this.getLotCodes(lot)
      return (subIdx + this.resultMinNum) === Number(lotCodes[setGroup])
    },
    getLotCodes (lot) {
      return lot.code.split(',')
    },
    generateLotNums (numStr) {
      return numStr.split(',')
    },
    generateResultNum (num) {
      if (this.lotInfo.rule.chart.resultFillZero) {
        return fillDigit(num, 2, '0')
      }
      return num
    },
    showCellContent (lot, subIdx) {
      if (this.showLotNum(subIdx, lot)) {
        return true
      }
      return this.leaksStatus
    },
    generateLotNumClass (lot, subIdx) {
      if (this.showLotNum(subIdx, lot)) {
        return 'lot-num'
      }
      return 'not-lot-num'
    },
    generateCellContent (lot, idx, subIdx) {
      const setGroup = this.getSubSetGroup()
      const lotCodes = this.getLotCodes(lot)
      if (this.showLotNum(subIdx, lot)) {
        if (this.lotInfo.rule.chart.resultFillZero) {
          return fillDigit(lotCodes[setGroup], 2, '0')
        }
        return lotCodes[setGroup]
      } else {
        if (this.showCellContent(lot, subIdx)) {
          return this.getLeaksNum(idx, subIdx)
        }
        return ''
      }
    },
    getLeaksNum (idx, subIdx) {
      if (!this.tableData) {
        return ''
      }
      const groupNextNumIdx = this.getGroupNextNumIdx(idx, subIdx)
      return groupNextNumIdx - idx
    },
    handleDrawLine () {
      setTimeout(() => {
        const tbody = this.$refs.tableBody.$el
        this.lotTd = tbody.firstChild.children[2]
        if (
          tbody &&
          this.tableData &&
          this.tableData.length !== 0
        ) {
          this.canvasW = tbody.offsetWidth
          this.canvasH = tbody.offsetHeight + (this.lotTd.offsetHeight * 2)
          // wait for component mounted
          setTimeout(() => {
            this.canvasDraw = this.$refs.tableCanvas.getContext('2d')
            this.clearCanvas()
            this.canvasDraw.strokeStyle = '#3cb0ec'
            this.drawBallLine()
          }, 10)
        }
      }, 10)
    },
    clearCanvas () {
      const canvas = this.$refs.tableCanvas
      this.canvasDraw.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight)
    },
    drawBallLine () {
      if (this.showLineStatus) {
        for (let i = 0, c = this.tableData.length - 1; i < c; i++) {
          const start = this.getBallPosition(i)
          const end = this.getBallPosition(i + 1)
          this.drawOneLine(start, end)
        }
      }
    },
    getBallPosition (ballIdx) {
      const ball = this.$refs.tableBody.$el.querySelectorAll('.lot-num')[ballIdx]
      return {
        x: ball.parentElement.offsetLeft + (this.lotTd.offsetWidth / 2),
        y: ball.parentElement.offsetTop + (this.lotTd.offsetHeight / 2)
      }
    },
    drawOneLine (start, end) {
      const c = this.canvasDraw
      c.beginPath()
      c.moveTo(start.x, start.y)
      c.lineTo(end.x, end.y)
      c.stroke()
    },
    generateFeatureClass (lot, idx, featureIdx) {
      const setGroup = this.getSubSetGroup()
      const checkNum = this.getLotCodes(lot)[setGroup]
      const groupNextFeatureIdx = this.getGroupNextFeatureIdx(0, featureIdx)
      switch (featureIdx) {
        case 0:
          // 单
          if (isOdd(checkNum)) {
            return 'odd-even'
          }
          break
        case 1:
          // 双
          if (!isOdd(checkNum)) {
            return 'odd-even'
          }
          break
        case 2:
          // 大
          if (
            isBig(checkNum, this.resultMaxNum, this.resultMinNum) === true ||
            isBig(checkNum, this.resultMaxNum, this.resultMinNum) === null
          ) {
            return 'big-small'
          }
          break
        case 3:
          // 小
          if (isBig(checkNum, this.resultMaxNum, this.resultMinNum) === false) {
            return 'big-small'
          }
          break
        case 4:
          // 质
          if (isPrime(checkNum)) {
            return 'prime'
          }
          break
        case 5:
          // 合
          if (!isPrime(checkNum)) {
            return 'prime'
          }
          break
      }
      if (
        this.leaksLevelStatus &&
        groupNextFeatureIdx - idx > 0
      ) {
        return 'leaksLevel'
      }
      return 'leaksLevel-no'
    },
    getGroupNextFeatureIdx (idx, featureIdx) {
      const nextIdx = this.findFeatureNextIdx(idx, featureIdx)
      if (nextIdx === -1) {
        return this.query.latest
      }
      return nextIdx
    },
    findFeatureNextIdx (idx, featureIdx) {
      const setGroup = this.getSubSetGroup()
      switch (featureIdx) {
        case 0:
          return findIndex(this.tableData, (i) => {
            return isOdd(i.code.split(',')[setGroup])
          }, idx)
        case 1:
          return findIndex(this.tableData, (i) => {
            return !isOdd(i.code.split(',')[setGroup])
          }, idx)
        case 2:
          return findIndex(this.tableData, (i) => {
            return (
              isBig(i.code.split(',')[setGroup], this.resultMaxNum, this.resultMinNum) === true ||
              isBig(i.code.split(',')[setGroup], this.resultMaxNum, this.resultMinNum) === null
            )
          }, idx)
        case 3:
          return findIndex(this.tableData, (i) => {
            return (isBig(i.code.split(',')[setGroup], this.resultMaxNum, this.resultMinNum) === false)
          }, idx)
        case 4:
          return findIndex(this.tableData, (i) => {
            return isPrime(i.code.split(',')[setGroup])
          }, idx)
        case 5:
          return findIndex(this.tableData, (i) => {
            return !isPrime(i.code.split(',')[setGroup])
          }, idx)
      }
    },
    generateFeatureContent (lot, idx, featureIdx) {
      const setGroup = this.getSubSetGroup()
      const checkNum = this.getLotCodes(lot)[setGroup]
      switch (featureIdx) {
        case 0:
          // 单
          if (isOdd(checkNum)) {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
        case 1:
          // 双
          if (!isOdd(checkNum)) {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
        case 2:
          // 大
          if (
            isBig(checkNum, this.resultMaxNum, this.resultMinNum) === true ||
            isBig(checkNum, this.resultMaxNum, this.resultMinNum) === null
          ) {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
        case 3:
          // 小
          if (isBig(checkNum, this.resultMaxNum, this.resultMinNum) === false) {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
        case 4:
          // 质
          if (isPrime(checkNum)) {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
        case 5:
          // 合
          if (!isPrime(checkNum)) {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
      }
    },
    getFeatureLeaksNum (idx, featureIdx) {
      if (
        !this.tableData ||
        !this.leaksStatus
      ) {
        return ''
      }
      const groupNextNumIdx = this.getGroupNextFeatureIdx(idx, featureIdx)
      return groupNextNumIdx - idx
    }
  },
  created () {
    this.initTableData()
  },
  watch: {
    updateStatus (val) {
      if (this.firstLoaded) {
        this.firstLoaded = false
      } else if (val) {
        this.initTableData()
      }
    },
    tableData (val) {
      if (val) {
        this.handleDrawLine()
      }
    },
    selCol (val) {
      this.handleDrawLine()
    },
    showLineStatus (val) {
      this.handleDrawLine()
    },
    pageSize (val) {
      this.initTableData()
    }
  }
}
</script>

<style lang="scss" scoped>
$bg-orange: #fbf6f0;
$bg-blue: #edf8fc;
$bg-green: #ecfcec;
$bg-purple: #ededfc;
$bg-emerald: #edfcf6;
$bg-yellow: #fcfced;
$bg-red: #fceded;
$bg-cyan: #edf2fc;
$bg-default: #edf2fc;

$circle-orange: #ee954b;
$circle-blue: #3594e3;
$circle-green: #55b810;
$circle-purple: #716cfa;
$circle-emerald: #4fb383;
$circle-yellow: #bed458;
$circle-red: #ce38bb;
$circle-cyan: #6bcdd0;
$circle-default: #6bcdd0;

$header-row-height: 43px;
$first-col-width: 12em;
$second-col-width: 15em;
// $col-min-width: 300px;
$num-cell-width: 18px;
.table-container {
  position: relative;
  height: max-content;
  overflow: auto;
}
table {
  // margin: 0 0 6px;
  thead {
    tr {
      th {
        border: 1px solid #dee2e6;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        border: 1px solid #dee2e6;
        vertical-align: middle;
      }
      .error-msg {color: #EB5757;}
      .result-nums-container {
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          margin-right: 5px;
          &:last-child{margin-right: 0;}
        }
      }
      .num-container {
        margin: 0 auto;
      }
      .lot-num {
        color: #ffffff;
        width: $num-cell-width;
        height: $num-cell-width;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 999;
      }
      .not-lot-num {
        width: $num-cell-width;
        height: $num-cell-width;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .leaksLevel {
        background-color: #9595c5 !important;
      }
      .digit-default {
        background-color: $bg-default;
        .lot-num {
          background-color: $circle-default;
        }
      }
      .digit-0 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-1 {
        background-color: $bg-blue;
        .lot-num {
          background-color: $circle-blue;
        }
      }
      .digit-2 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .digit-3 {
        background-color: $bg-purple;
        .lot-num {
          background-color: $circle-purple;
        }
      }
      .digit-4 {
        background-color: $bg-emerald;
        .lot-num {
          background-color: $circle-emerald;
        }
      }
      .digit-5 {
        background-color: $bg-yellow;
        .lot-num {
          background-color: $circle-yellow;
        }
      }
      .digit-6 {
        background-color: $bg-red;
        .lot-num {
          background-color: $circle-red;
        }
      }
      .digit-7 {
        background-color: $bg-cyan;
        .lot-num {
          background-color: $circle-cyan;
        }
      }
      .digit-8 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-9 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .odd-even {
        background-color: #46bd95;
      }
      .big-small {
        background-color: #8585fb;
      }
      .prime {
        background-color: #3cb0ec;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  // .b-table-sticky-header {
  //   max-height: 600px;
  // }
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
          // left: $first-col-width !important;
        }
        // th:not(:nth-child(1)):not(:nth-child(2)) {
        //   // min-width: $col-min-width;
        //   // min-width: var(--col-min-width);
        // }
      }
      // tr:nth-child(2) {
      //   th {
      //     top: $header-row-height !important;
      //   }
      // }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        // td:nth-child(2) {
        //   // left: $first-col-width !important;
        // }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
</style>
