import { render, staticRenderFns } from "./NumAnalysis.vue?vue&type=template&id=dc7ce848&scoped=true&"
import script from "./NumAnalysis.vue?vue&type=script&lang=js&"
export * from "./NumAnalysis.vue?vue&type=script&lang=js&"
import style0 from "./NumAnalysis.vue?vue&type=style&index=0&id=dc7ce848&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc7ce848",
  null
  
)

export default component.exports