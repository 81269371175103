<template>
  <div class="content-container">
    <Head
      :pageSize="pageSize"
      :leaksStatus="leaksStatus"
      :leaksLevelStatus="leaksLevelStatus"
      :showLineStatus="showLineStatus"
      @handleChangePageSize="handleChangePageSize"
      @handleLeaksStatus="handleLeaksStatus"
      @handleLeaksLevelStatus="handleLeaksLevelStatus"
      @handleShowLineStatus="handleShowLineStatus" />
    <div class="main-container">
      <Main
        :updateStatus="updateStatus"
        :lotTotalIssue="lotTotalIssue"
        :pageSize="pageSize"
        :leaksStatus="leaksStatus"
        :leaksLevelStatus="leaksLevelStatus"
        :showLineStatus="showLineStatus" />
    </div>
  </div>
</template>

<script>
import Head from './Head'
import Main from './Main'

export default {
  components: {
    Head,
    Main
  },
  props: {
    updateStatus: {
      type: Boolean,
      required: true
    },
    lotTotalIssue: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      pageSize: 50,
      leaksStatus: true,
      leaksLevelStatus: false,
      showLineStatus: true
    }
  },
  methods: {
    handleLeaksStatus (boo) {
      this.leaksStatus = boo
    },
    handleLeaksLevelStatus (boo) {
      this.leaksLevelStatus = boo
    },
    handleShowLineStatus (boo) {
      this.showLineStatus = boo
    },
    handleChangePageSize (num) {
      this.pageSize = num
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 575.98px) {
  .main-container{background-color: #FFF;}
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-container{background-color: #FFF;}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-container{padding: 0 15px 15px;}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-container{padding: 0 15px 15px;}
}
@media (min-width: 1200px) {
  .main-container{padding: 0 15px 15px;}
}
</style>