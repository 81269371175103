<template>
  <div
    class="table-container"
    :class="isMobile && tableData ? 'fixed-height' : ''">
    <canvas
      ref="tableCanvas"
      style="position:absolute;top:0;left:0;z-index:99;"
      :width="canvasW"
      :height="canvasH" />
    <b-table-simple>
      <!-- column group -->
      <!-- 开奖期数 -->
      <colgroup><col></colgroup>
      <!-- 开奖号码 -->
      <colgroup><col></colgroup>

      <!-- table head -->
      <b-thead>
        <!-- first row -->
        <b-tr>
          <!-- 开奖期数 -->
          <b-th rowspan="2">
            开奖期数
          </b-th>
          <!-- 开奖号码 -->
          <b-th rowspan="2">
            开奖号码
          </b-th>
          <!-- 开奖号码分布 -->
          <b-th :colspan="resultNums.length">
            开奖号码分布
          </b-th>
          <!-- 百位, 十位, 个位 -->
          <b-th
            v-for="(head, idx) in chartRule.headArr"
            :key="'head' + idx"
            colspan="2">
            {{ head }}
          </b-th>
          <!-- 奇偶比走势 -->
          <b-th :colspan="oeTrends.length">
            奇偶比走势
          </b-th>
          <!-- 奇数个数 -->
          <b-th :colspan="headLen + 1">
            奇数个数
          </b-th>
          <!-- 偶数个数 -->
          <b-th :colspan="headLen + 1">
            偶数个数
          </b-th>
        </b-tr>
        <!-- second row -->
        <b-tr>
          <!-- 开奖号码分布 -->
          <b-th
            v-for="(num, idx) in resultNums"
            :key="'result-col' + idx">
            {{ num }}
          </b-th>
          <!-- 百位, 十位, 个位 -->
          <b-th
            v-for="(subHead, idx) in subHeads"
            :key="'subHead-' + idx">
            {{ subHead }}
          </b-th>
          <!-- 奇偶比走势 -->
          <b-th
            v-for="(trendSet, idx) in oeTrends"
            :key="'trendSet-' + idx">
            {{ trendSet }}
          </b-th>
          <!-- 奇数个数 -->
          <b-th
            v-for="(count, idx) in oeCounts"
            :key="'o-count-' + idx">
            {{ count }}
          </b-th>
          <!-- 偶数个数 -->
          <b-th
            v-for="(count, idx) in oeCounts"
            :key="'e-count-' + idx">
            {{ count }}
          </b-th>
        </b-tr>
      </b-thead>

      <!-- table body -->
      <b-tbody ref="tableBody">
        <b-tr v-if="!tableData">
          <b-td
            :colspan="tableColNum"
            :class="showMsg == 'error' ? 'error-msg' : ''">
            {{tableMsg[showMsg]}}
          </b-td>
        </b-tr>
        <b-tr
          v-else
          v-for="(lot, idx) in tableData"
          :key="'row-' + idx">
          <!-- idx -->
          <!-- 开奖期数 -->
          <b-td>{{ lot.issue }}</b-td>
          <!-- 开奖号码 -->
          <b-td>
            <div class="result-nums-container">
              <div
                v-for="(num, numIdx) in generateLotNums(lot.code)"
                :key="'lot-num-' + lot.id + numIdx">{{ generateResultNum(num) }}</div>
            </div>
          </b-td>
          <!-- 开奖号码分布 -->
          <b-td
            v-for="(resultNum, disIdx) in resultNums"
            :key="'row-' + lot.id + '-result-col' + disIdx"
            :class="generateDistributedContainerClass(lot, idx, disIdx)">
            <div :class="generateDistributedContentClass(lot, disIdx)">
              {{ generateDistributedContent(lot, idx, disIdx) }}
            </div>
          </b-td>
          <!-- 百位, 十位, 个位 -->
          <b-td
            v-for="(subHead, subIdx) in subHeads"
            :key="'row-' + lot.id + '-subhead-idx-' + subIdx"
            :class="generateDifitClass(lot, idx, subIdx)">
            {{ generateDigitContent(lot, idx, subIdx) }}
            </b-td>
          <!-- 奇偶比走势 -->
          <b-td
            v-for="(trendSet, trendIdx) in oeTrends"
            :key="'row-' + lot.id + '-trend-idx-' + trendIdx"
            :class="generateTrendClass(lot, idx, trendIdx)">
            {{ generateTrendContent(lot, idx, trendIdx) }}
          </b-td>
          <!-- 奇数个数 -->
          <b-td
            v-for="(count, ocIdx) in oeCounts"
            :key="'row-' + lot.id + '-oc-idx-' + ocIdx"
            :class="generateCountContainerClass(lot, idx, ocIdx, 'odd')">
            <div :class="generateCountContentClass(lot, ocIdx, 'odd')">
              {{ generateOddCountContent(lot, idx, ocIdx) }}
            </div>
          </b-td>
          <!-- 偶数个数 -->
          <b-td
            v-for="(count, ecIdx) in oeCounts"
            :key="'row-' + lot.id + '-ec-idx-' + ecIdx"
            :class="generateCountContainerClass(lot, idx, ecIdx)">
            <div :class="generateCountContentClass(lot, ecIdx)">
              {{ generateEvenCountContent(lot, idx, ecIdx) }}
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { allLots } from '@/constants/lots'
import { getLotWinNumHistory } from '@/api/client/lot'
import { getLotInfoById, isOdd } from '@/utils/lot'
import { range, indexOf, findIndex } from 'lodash'
import { fillDigit } from '@/utils/string'
import { isMobile } from '@/utils/device'

export default {
  props: {
    updateStatus: {
      type: Boolean,
      required: true
    },
    lotTotalIssue: {
      type: Number,
      required: true
    },
    pageSize: {
      type: [Number, String],
      required: true
    },
    leaksStatus: {
      type: Boolean,
      required: true
    },
    leaksLevelStatus: {
      type: Boolean,
      required: true
    },
    showLineStatus: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    lotId: {
      get () {
        if (!this.$route.query.id) {
          return allLots[0].children[0].id
        }
        return this.$route.query.id
      }
    },
    lotInfo: {
      get () {
        return (
          getLotInfoById(this.lotId)
        )
      }
    },
    chartRule: {
      get () {
        return this.lotInfo.rule.chart
      }
    },
    query: {
      get () {
        const objQuery = {
          // date: '2021-03-01',
          code: this.lotId,
          page: 1
        }

        switch (typeof this.pageSize) {
          case 'string':
            objQuery.date = this.pageSize
            break
          case 'number':
            // objQuery.pageSize = this.pageSize
            // objQuery.date = null
            objQuery.latest = this.pageSize
            break
        }

        return objQuery
      }
    },
    resultMinNum: {
      get () {
        return this.chartRule.resultMinNum
      }
    },
    resultMaxNum: {
      get () {
        return this.chartRule.resultMaxNum
      }
    },
    resultNums: {
      get () {
        return range(this.resultMinNum, this.resultMaxNum + 1)
      }
    },
    tableColNum: {
      get () {
        return this.subHeads.length + this.oeTrends.length + this.oeCounts.length * 2 + this.featureList.length * 3 + 2
      }
    },
    headLen: {
      get () {
        return this.chartRule.headArr.length
      }
    },
    subHeads: {
      get () {
        let subHeads = []
        for (let i = 0; i < this.headLen; i++) {
          subHeads = [...subHeads, ...this.featureList]
        }
        return subHeads
      }
    },
    oeTrends: {
      get () {
        return (
          range(0, this.headLen + 1)
            .map(val => {
              return `${this.headLen - val}:${val}`
            })
        )
      }
    },
    oeCounts: {
      get () {
        return range(0, this.headLen + 1)
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  data () {
    return {
      firstLoaded: true,
      canvasW: 0,
      canvasH: 0,
      tableData: null,
      featureList: ['奇', '偶'],
      showMsg: '',
      tableMsg: {
        error: '發生错误，请稍后再试',
        empty: '目前尚无资料',
        loading: '获取资料中...'
      }
    }
  },
  methods: {
    init () {
      this.showMsg = 'loading'
      getLotWinNumHistory(this.query)
        .then(res => {
          if (res.status === resStatus.OK) {
            if (res.data.length === 0) {
              this.showMsg = 'empty'
              this.emptyPageData()
            } else {
              this.tableData = res.data
            }
          } else {
            this.showMsg = 'error'
            this.emptyPageData()
          }
        })
    },
    emptyPageData () {
      this.tableData = null
      this.canvasW = 0
      this.canvasH = 0
    },
    getQueryPageSize () {
      switch (typeof this.pageSize) {
        case 'string':
          return 50
        case 'number':
          return this.pageSize
      }
    },
    getQueryDate () {
      switch (typeof this.pageSize) {
        case 'string':
          return this.pageSize
        case 'number':
          return null
      }
    },
    generateLotNums (numStr) {
      return numStr.split(',')
    },
    generateResultNum (num) {
      if (this.chartRule.resultFillZero) {
        return fillDigit(num, 2, '0')
      }
      return num
    },
    // 开奖号码分布
    generateDistributedContainerClass (lot, rowIdx, disIdx) {
      const cellClass = 'digit-1'
      const disNextNumIdx = this.getDisNextNumIdx(0, disIdx)

      // 遗漏分层 class
      if (
        !this.showDisTrubutedNum(lot, disIdx) &&
        this.leaksLevelStatus &&
        disNextNumIdx - rowIdx > 0
      ) {
        return `${cellClass} leaksLevel`
      }
      return `${cellClass} leaksLevel-no`
    },
    generateDistributedContentClass (lot, disIdx) {
      if (this.showDisTrubutedNum(lot, disIdx)) {
        const contentClass = 'lot-num'
        switch (this.countLotNum(lot, disIdx)) {
          case 2:
            return `${contentClass} lot-num-has-2`
          case 3:
            return `${contentClass} lot-num-has-3`
        }
        return contentClass
      }
      return 'not-lot-num'
    },
    countLotNum (lot, disIdx) {
      const disNums = lot.code.split(',')
      const countNum = disIdx + 1
      let count = 0

      for (let i = 0, c = disNums.length; i < c; i++) {
        if (Number(disNums[i]) === countNum) {
          count += 1
        }
      }

      return count
    },
    generateDistributedContent (lot, rowIdx, disIdx) {
      if (this.showDisTrubutedNum(lot, disIdx)) {
        return this.resultNums[disIdx]
      } else {
        if (this.leaksStatus) {
          return this.getDistrubutedLeaksNum(rowIdx, disIdx)
        }
      }
    },
    showDisTrubutedNum (lot, disIdx) {
      const disNums = lot.code.split(',')
      const resultNum = this.resultNums[disIdx]

      return (
        findIndex(disNums, (ele) => {
          return Number(ele) === Number(resultNum)
        }) !== -1
      )
    },
    getDistrubutedLeaksNum (rowIdx, disIdx) {
      // if (!this.tableData) {
      //   return ''
      // }
      const disNextNumIdx = this.getDisNextNumIdx(rowIdx, disIdx)
      return disNextNumIdx - rowIdx
    },
    getDisNextNumIdx (rowIdx, disIdx) {
      const resultNum = this.resultNums[disIdx]
      const nextIdx = findIndex(this.tableData, (i) => {
        return this.hasDisNumInCode(i, resultNum)
      }, rowIdx)
      if (nextIdx === -1) {
        return this.tableData.length
      }
      return nextIdx
    },
    hasDisNumInCode (rowData, resultNum) {
      const rowCodes = rowData.code.split(',')

      return indexOf(rowCodes, String(resultNum)) !== -1
    },
    // 百位, 十位, 个位
    generateDifitClass (lot, rowIdx, subIdx) {
      const cellClass = 'basic-cell'
      const rowCodes = lot.code.split(',')
      const digitNum = this.getDigitNum(rowCodes, subIdx)
      const digitNextNumIdx = this.getDigitNextOEIdx(0, subIdx)

      if (
        isOdd(digitNum) &&
        !isOdd(subIdx)
      ) {
        return `${cellClass} odd-cell`
      } else if (
        !isOdd(digitNum) &&
        isOdd(subIdx)
      ) {
        return `${cellClass} even-cell`
      }
      // 遗漏分层 class
      if (
        this.leaksLevelStatus &&
        digitNextNumIdx - rowIdx > 0
      ) {
        return `${cellClass} leaksLevel`
      }
      return `${cellClass} leaksLevel-no`
    },
    generateDigitContent (lot, rowIdx, subIdx) {
      const rowCodes = lot.code.split(',')
      const digitNum = this.getDigitNum(rowCodes, subIdx)
      if (
        isOdd(digitNum) &&
        !isOdd(subIdx)
      ) {
        return '奇'
      } else if (
        !isOdd(digitNum) &&
        isOdd(subIdx)
      ) {
        return '偶'
      } else {
        if (this.leaksStatus) {
          return this.getDigitLeaksNum(rowIdx, subIdx)
        }
      }
    },
    getDigitNum (rowCodes, subIdx) {
      switch (subIdx) {
        case 0:
        case 1:
          // 百位
          return rowCodes[0]
        case 2:
        case 3:
          // 十位
          return rowCodes[1]
        case 4:
        case 5:
          // 个位
          return rowCodes[2]
      }
    },
    getDigitLeaksNum (rowIdx, subIdx) {
      const digitNextNumIdx = this.getDigitNextOEIdx(rowIdx, subIdx)
      return digitNextNumIdx - rowIdx
    },
    getDigitNextOEIdx (rowIdx, subIdx) {
      const nextOEIdx = findIndex(this.tableData, (i) => {
        if (!isOdd(subIdx)) {
          return this.findNextOdd(i, subIdx)
        } else {
          return this.findNextEven(i, subIdx)
        }
      }, rowIdx)
      if (nextOEIdx === -1) {
        return this.tableData.length
      }
      return nextOEIdx
    },
    findNextOdd (rowData, subIdx) {
      const rowCodes = rowData.code.split(',')
      const digitNum = this.getDigitNum(rowCodes, subIdx)

      if (isOdd(digitNum)) {
        return true
      }
      return false
    },
    findNextEven (rowData, subIdx) {
      const rowCodes = rowData.code.split(',')
      const digitNum = this.getDigitNum(rowCodes, subIdx)

      if (!isOdd(digitNum)) {
        return true
      }
      return false
    },
    // 奇偶比走势
    generateTrendClass (lot, rowIdx, trendIdx) {
      const cellClass = 'basic-cell'
      const rowCodes = lot.code.split(',')
      const evenCount = this.getEvenCount(rowCodes)
      const trendNextIdx = this.getTrendNextIdx(0, trendIdx)

      if (evenCount === trendIdx) {
        if (trendIdx % 2 === 0) {
          return `${cellClass} odd-cell`
        }
        return `${cellClass} even-cell`
      }
      // 遗漏分层 class
      if (
        this.leaksLevelStatus &&
        trendNextIdx - rowIdx > 0
      ) {
        return `${cellClass} leaksLevel`
      }
      return `${cellClass} leaksLevel-no`
    },
    generateTrendContent (lot, rowIdx, trendIdx) {
      const rowCodes = lot.code.split(',')
      const evenCount = this.getEvenCount(rowCodes)

      if (evenCount === trendIdx) {
        return this.oeTrends[trendIdx]
      } else {
        if (this.leaksStatus) {
          return this.getTrendLeaksNum(rowIdx, trendIdx)
        }
      }
    },
    getEvenCount (rowCodes) {
      let evenCount = 0

      for (let i = 0, c = rowCodes.length; i < c; i++) {
        if (!isOdd(rowCodes[i])) {
          evenCount += 1
        }
      }

      return evenCount
    },
    getTrendLeaksNum (rowIdx, trendIdx) {
      const trendNextIdx = this.getTrendNextIdx(rowIdx, trendIdx)
      return trendNextIdx - rowIdx
    },
    getTrendNextIdx (rowIdx, trendIdx) {
      const nextIdx = findIndex(this.tableData, (i) => {
        return this.findNextTrend(i, trendIdx)
      }, rowIdx)
      if (nextIdx === -1) {
        return this.tableData.length
      }
      return nextIdx
    },
    findNextTrend (rowData, trendIdx) {
      const rowCodes = rowData.code.split(',')
      const evenCount = this.getEvenCount(rowCodes)

      return evenCount === trendIdx
    },
    // 奇数个数
    generateCountContainerClass (lot, rowIdx, cIdx, type = 'even') {
      const cellClass = 'digit-default digit-1'
      const countNextIdx = this.getCountNextIdx(0, cIdx, type)

      // 遗漏分层 class
      if (
        !this.showCountContent(lot, cIdx, type) &&
        this.leaksLevelStatus &&
        countNextIdx - rowIdx > 0
      ) {
        return `${cellClass} leaksLevel`
      }
      return `${cellClass} leaksLevel-no`
    },
    generateCountContentClass (lot, cIdx, type = 'even') {
      if (this.showCountContent(lot, cIdx, type)) {
        return `lot-num ${this.generateCountBallClass(type)}`
      }
      return 'not-lot-num'
    },
    generateCountBallClass (type) {
      return `lot-${type}`
    },
    generateOddCountContent (lot, rowIdx, cIdx) {
      if (this.showCountContent(lot, cIdx, 'odd')) {
        return this.oeCounts[cIdx]
      } else {
        if (this.leaksStatus) {
          return this.getCountLeaksNum(rowIdx, cIdx, 'odd')
        }
      }
    },
    showCountContent (lot, cIdx, type = 'even') {
      const rowCodes = lot.code.split(',')
      const evenCount = this.getEvenCount(rowCodes)
      const oddCount = this.headLen - evenCount

      if (type === 'odd') {
        return oddCount === cIdx
      }
      return evenCount === cIdx
    },
    getCountLeaksNum (rowIdx, cIdx, type = 'even') {
      const countNextIdx = this.getCountNextIdx(rowIdx, cIdx, type)
      return countNextIdx - rowIdx
    },
    getCountNextIdx (rowIdx, cIdx, type) {
      const nextIdx = findIndex(this.tableData, (i) => {
        return this.findNextCount(i, cIdx, type)
      }, rowIdx)
      if (nextIdx === -1) {
        return this.tableData.length
      }
      return nextIdx
    },
    findNextCount (rowData, cIdx, type) {
      const rowCodes = rowData.code.split(',')
      const eventCount = this.getEvenCount(rowCodes)
      const oddCount = this.headLen - eventCount

      if (type === 'odd') {
        return oddCount === cIdx
      }
      return eventCount === cIdx
    },
    // 偶数个数
    generateEvenCountContent (lot, rowIdx, cIdx) {
      if (this.showCountContent(lot, cIdx)) {
        return this.oeCounts[cIdx]
      } else {
        if (this.leaksStatus) {
          return this.getCountLeaksNum(rowIdx, cIdx)
        }
      }
    },
    // handle canvas
    handleDrawLine () {
      setTimeout(() => {
        const tbody = this.$refs.tableBody.$el
        this.lotTd = tbody.offsetHeight > 0 ? tbody.firstChild.children[2] : null
        if (
          tbody &&
          this.tableData &&
          this.tableData.length !== 0 &&
          this.lotTd
        ) {
          this.canvasW = tbody.offsetWidth
          this.canvasH = tbody.offsetHeight + (this.lotTd.offsetHeight * 2)
          // wait for component mounted
          setTimeout(() => {
            this.canvasDraw = this.$refs.tableCanvas.getContext('2d')
            this.clearCanvas()
            this.canvasDraw.strokeStyle = '#3cb0ec'
            this.drawBallLine()
          }, 10)
        } else {
          this.clearCanvas()
        }
      }, 10)
    },
    clearCanvas () {
      const canvas = this.$refs.tableCanvas
      if (this.canvasDraw) {
        this.canvasDraw.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight)
      }
    },
    drawBallLine () {
      const arrLot = ['odd', 'even']
      if (this.showLineStatus) {
        for (let i = 0, c = arrLot.length; i < c; i++) {
          for (let j = 0, d = this.tableData.length - 1; j < d; j++) {
            const start = this.getBallPosition(arrLot[i], j)
            const end = this.getBallPosition(arrLot[i], j + 1)
            this.drawOneLine(start, end)
          }
        }
      }
    },
    getBallPosition (ballCol, ballIdx) {
      const ball = this.$refs.tableBody.$el.querySelectorAll(`.lot-${ballCol}`)[ballIdx]
      return {
        x: ball.parentElement.offsetLeft + (this.lotTd.offsetWidth / 2),
        y: ball.parentElement.offsetTop + (this.lotTd.offsetHeight / 2)
      }
    },
    drawOneLine (start, end) {
      // console.log('draw one line: ', start, end)
      const c = this.canvasDraw
      c.beginPath()
      c.moveTo(start.x, start.y)
      c.lineTo(end.x, end.y)
      c.stroke()
    }
  },
  created () {
    this.init()
  },
  watch: {
    updateStatus (val) {
      if (this.firstLoaded) {
        this.firstLoaded = false
      } else if (val) {
        this.init()
      }
    },
    pageSize (val) {
      switch (typeof val) {
        case 'string':
          // this.query.pageSize = this.lotTotalIssue
          this.query.pageSize = 100
          this.query.date = val
          break
        case 'number':
          this.query.latest = val
          // this.query.pageSize = val
          // this.query.date =
          break
      }
      this.init()
    },
    tableData (val) {
      if (val) {
        this.handleDrawLine()
      }
    },
    showLineStatus (val) {
      this.handleDrawLine()
    }
  }
}
</script>

<style lang="scss" scoped>
$bg-orange: #fbf6f0;
$bg-blue: #edf8fc;
$bg-green: #ecfcec;
$bg-purple: #ededfc;
$bg-emerald: #edfcf6;
$bg-yellow: #fcfced;
$bg-red: #fceded;
$bg-cyan: #edf2fc;
$bg-default: #edf2fc;

$circle-orange: #ee954b;
$circle-blue: #3594e3;
$circle-green: #55b810;
$circle-purple: #716cfa;
$circle-emerald: #4fb383;
$circle-yellow: #bed458;
$circle-red: #ce38bb;
$circle-cyan: #6bcdd0;
$circle-default: #6bcdd0;

$header-row-height: 43px;
$first-col-width: 12em;
$second-col-width: 12em;
// $col-min-width: 300px;
$num-cell-width: 18px;
.table-container {
  position: relative;
  height: max-content;
  overflow: auto;
}
table {
  // margin: 0 0 6px;
  thead {
    tr {
      th {
        border: 1px solid #dee2e6;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        border: 1px solid #dee2e6;
        vertical-align: middle;
      }
      .error-msg {color: #EB5757;}
      .result-nums-container {
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          margin-right: 5px;
          &:last-child{margin-right: 0;}
        }
      }
      .num-container {
        margin: 0 auto;
      }
      .lot-num {
        color: #ffffff;
        width: $num-cell-width;
        height: $num-cell-width;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 999;
      }
      .lot-num-has-2::before {
        content: '2';
        width: 12px;
        height: 12px;
        line-height: 12px;
        text-align: center;
        border-radius: 50%;
        background-color: #EB5757;
        position: absolute;
        top: -5px;
        right: -5px;
        font-size: 10px;
      }
      .lot-num-has-3::before {
        content: '3';
        width: 12px;
        height: 12px;
        line-height: 12px;
        text-align: center;
        border-radius: 50%;
        background-color: #EB5757;
        position: absolute;
        top: -5px;
        right: -5px;
        font-size: 10px;
      }
      .not-lot-num {
        width: $num-cell-width;
        height: $num-cell-width;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .leaksLevel {
        background-color: #9595c5 !important;
        color: #ffffff;
      }
      .digit-default {
        background-color: $bg-default;
        .lot-num {
          background-color: $circle-default;
        }
      }
      .digit-0 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-1 {
        background-color: $bg-blue;
        .lot-num {
          background-color: $circle-blue;
        }
      }
      .digit-2 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .digit-3 {
        background-color: $bg-purple;
        .lot-num {
          background-color: $circle-purple;
        }
      }
      .digit-4 {
        background-color: $bg-emerald;
        .lot-num {
          background-color: $circle-emerald;
        }
      }
      .digit-5 {
        background-color: $bg-yellow;
        .lot-num {
          background-color: $circle-yellow;
        }
      }
      .digit-6 {
        background-color: $bg-red;
        .lot-num {
          background-color: $circle-red;
        }
      }
      .digit-7 {
        background-color: $bg-cyan;
        .lot-num {
          background-color: $circle-cyan;
        }
      }
      .digit-8 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-9 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .basic-cell {
        min-width: $num-cell-width;
        min-height: $num-cell-width;
      }
      .odd-cell {
        color: #ffffff;
        background-color: $circle-orange;
      }
      .even-cell {
        color: #ffffff;
        background-color: $circle-blue;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  // .b-table-sticky-header {
  //   max-height: 600px;
  // }
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
</style>
