<template>
  <div
    class="table-container"
    :class="isMobile && tableData ? 'fixed-height' : ''">
    <canvas
      ref="tableCanvas"
      style="position:absolute; top:0; left:0; z-index:99;"
      :width="canvasW"
      :height="canvasH">
    </canvas>
    <!-- sticky-header -->
    <b-table-simple>
      <!-- column group -->
      <!-- 开奖期数 -->
      <colgroup><col></colgroup>
      <!-- 开奖号码 -->
      <colgroup><col></colgroup>
      <!-- 選擇選項 -->
      <colgroup>
        <col
          v-for="(subHead, subIdx) in subHeadSet"
          :key="'set-sub-col-' + subIdx">
      </colgroup>
      <!-- 形态特征 -->
      <colgroup>
        <col
          v-for="(item, idx) in formList"
          :key="'form-col-' + idx">
      </colgroup>
      <!-- 选组类型 -->
      <colgroup>
        <col
          v-for="(item, idx) in formList"
          :key="'sel-col-' + idx">
      </colgroup>

      <!-- table head -->
      <b-thead>
        <b-tr>
          <!-- 开奖期数 -->
          <b-th
            rowspan="2">
            开奖期数
          </b-th>
          <!-- 开奖号码 -->
          <b-th
            rowspan="2">
            开奖号码
          </b-th>
          <!-- 選擇選項 -->
          <b-th colspan="10">
            第一球			
          </b-th>
          <b-th colspan="10">
            第二球			
          </b-th>
          <b-th colspan="10">
            第三球			
          </b-th>
          <!-- 形态 -->
          <b-th colspan="5">
            形态
          </b-th>
          <b-th colspan="3">
            选组类型
          </b-th>
        </b-tr>
        <b-tr>
          <!-- 數字 -->
          <b-th
            v-for="num in numArr"
            :key="'sub-head-num-' + num.key">
            {{ num.text }}
          </b-th>
          <!-- 形态 -->
          <b-th
            v-for="(item, idx) in formList"
            :key="'sub-head-form-col-' + idx">
            {{ item }}
          </b-th>
          <!-- 选组类型 -->
          <b-th
            v-for="(item, idx) in selList"
            :key="'sub-head-sel-col-' + idx">
            {{ item }}
          </b-th>
        </b-tr>
      </b-thead>

      <!-- table body -->
      <b-tbody ref="tableBody">
        <b-tr v-if="!tableData">
          <b-td
            :colspan="tableColNum"
            :class="showMsg == 'error' ? 'error-msg' : ''">
            {{tableMsg[showMsg]}}
          </b-td>
        </b-tr>
        <b-tr
          v-else
          v-for="(lot, idx) in tableData"
          :key="lot.id">
          <!-- 开奖期数 -->
          <b-td>{{ lot.issue }}</b-td>
          <!-- 开奖号码 -->
          <b-td>
            <div class="result-nums-container">
              <div
                v-for="(num, numIdx) in generateLotNums(lot.code)"
                :key="'lot-num-' + lot.id + numIdx">{{ generateResultNum(num) }}</div>
            </div>
          </b-td>
          <!-- 第一球 -->
          <b-td
            v-for="(subNum, subIdx) in subHeadSet"
            :key="'row-' + lot.id + '-first-idx-' + subIdx"
            :class="generateCellClass(lot, idx, subIdx, 0)">
            <div
              class="num-container"
              :class="generateLotNumClass(lot, subIdx, 0)">
              {{ generateCellContent(lot, idx, subIdx, 0) }}
            </div>
          </b-td>
          <!-- 第二球 -->
          <b-td
            v-for="(subNum, subIdx) in subHeadSet"
            :key="'row-' + lot.id + '-second-idx-' + subIdx"
            :class="generateCellClass(lot, idx, subIdx, 1)">
            <div
              class="num-container"
              :class="generateLotNumClass(lot, subIdx, 1)">
              {{ generateCellContent(lot, idx, subIdx, 1) }}
            </div>
          </b-td>
          <!-- 第三球 -->
          <b-td
            v-for="(subNum, subIdx) in subHeadSet"
            :key="'row-' + lot.id + '-third-idx-' + subIdx"
            :class="generateCellClass(lot, idx, subIdx, 2)">
            <div
              class="num-container"
              :class="generateLotNumClass(lot, subIdx, 2)">
              {{ generateCellContent(lot, idx, subIdx, 2) }}
            </div>
          </b-td>
          <!-- 形态特征 -->
          <b-td
            v-for="(form, formIdx) in formList"
            :key="'row' + lot.id + '-form-idx-' + formIdx"
            :class="generateformClass(lot, idx, formIdx)">
            {{ generateformContent(lot, idx, formIdx) }}
          </b-td>
          <!-- 选组类型 -->
          <b-td
            v-for="(sel, selIdx) in selList"
            :key="'row' + lot.id + '-sel-idx-' + selIdx"
            :class="generateSelClass(lot, idx, selIdx)">
            {{ generateSelContent(lot, idx, selIdx) }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'
import { getLotWinNumHistory } from '@/api/client/lot'
import resStatus from '@/constants/resStatus'
import { getLotInfoById, isThreeKind, isStraight, isPair, isHalfStraight, isAllDiff } from '@/utils/lot'
import { range, last, findIndex } from 'lodash'
import { fillDigit } from '@/utils/string'
import { isMobile } from '@/utils/device'

export default {
  props: {
    updateStatus: {
      type: Boolean,
      required: true
    },
    selCol: {
      type: String,
      required: true
    },
    leaksStatus: {
      type: Boolean,
      required: true
    },
    leaksLevelStatus: {
      type: Boolean,
      required: true
    },
    showLineStatus: {
      type: Boolean,
      required: true
    },
    pageSize: {
      type: [Number, String],
      required: true
    },
  },
  data () {
    return {
      firstLoaded: true,
      tableData: null,
      formList: ['豹子', '顺子', '对子', '半顺', '杂六'],
      selList: ['组三', '组六', '豹子'],
      canvasW: 0,
      canvasH: 0,
      canvasDraw: null,
      lotTd: null,
      showMsg: '',
      tableMsg: {
        error: '發生错误，请稍后再试',
        empty: '目前尚无资料',
        loading: '获取资料中...'
      }
    }
  },
  computed: {
    lotId: {
      get () {
        if (!this.$route.query.id) {
          return allLots[0].children[0].id
        }
        return this.$route.query.id
      }
    },
    lotInfo: {
      get () {
        return (
          getLotInfoById(this.lotId)
        )
      }
    },
    query: {
      get () {
        let objQuery = {
          // pageSize: this.pageSize,
          // date: '2021-03-01',
          code: this.lotId,
          page: 1
        }

        switch (typeof this.pageSize) {
          case 'string':
            objQuery.date = this.pageSize
            break
          case 'number':
            objQuery.latest = this.pageSize
            break
        }

        return objQuery
      }
    },
    resultMinNum: {
      get () {
        return this.lotInfo.rule.chart.resultMinNum
      }
    },
    resultMaxNum: {
      get () {
        return this.lotInfo.rule.chart.resultMaxNum
      }
    },
    numArr: {
      get () {
        let arr = []
        for(let i = 0; i < 3; i++) {
          for(let j = 0; j < 10; j++){
            arr.push({
              text: j,
              key: `num-col-${i}-${j}`
            })
          }
        }
        return arr
      }
    },
    subHeadSet: {
      get () {
        return range(this.resultMinNum, this.resultMaxNum + 1)
      }
    },
    tableColNum: {
      get () {
        return this.subSetLen * 3 + this.formList.length + this.selList.length + 2
      }
    },
    subSetLen: {
      get () {
        return this.subHeadSet.length
      }
    },
    lotType: {
      get () {
        return last(this.selCol.split('-'))
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    handleUpdateStatus (boo) {
      this.$emit('handleUpdateStatus', boo)
    },
    initTableData () {
      this.showMsg = 'loading'
      getLotWinNumHistory(this.query)
        .then(res => {
          this.handleUpdateStatus(false)
          if (res.status === resStatus.OK) {
            if (res.data.length === 0) {
              this.showMsg = 'empty'
              this.emptyPageData()
            } else {
              this.tableData = res.data
              this.showMsg = ''
            }
          } else {
            this.showMsg = 'error'
            this.emptyPageData()
          }
        })
    },
    emptyPageData () {
      this.tableData = null
      this.canvasW = 0
      this.canvasH = 0
    },
    generateCellClass (lot, idx, subIdx, position) {
      const cellClass = `digit-default digit-${position}`
      const groupNextNumIdx = this.getGroupNextNumIdx(idx, subIdx, position)
      if (
        !this.showLotNum(subIdx, lot) &&
        this.leaksLevelStatus &&
        groupNextNumIdx - idx > 0
      ) {
        return `${cellClass} leaksLevel`
      }
      return `${cellClass} leaksLevel-no`
    },
    getSubSetGroup () {
      return Number(this.lotType)
    },
    getGroupNextNumIdx (idx, subIdx, position) {
      // const setGroup = this.getSubSetGroup()
      const subIdxNum = subIdx % this.subSetLen + this.resultMinNum
      const nextIdx = findIndex(this.tableData, (i) => {
        return Number(this.getLotCodes(i)[position]) === subIdxNum
      }, idx)
      if (nextIdx === -1) {
        // return this.query.latest
        return this.tableData.length
      }
      return nextIdx
    },
    showLotNum (subIdx, lot, position) {
      // const setGroup = this.getSubSetGroup()
      const lotCodes = this.getLotCodes(lot)
      return (subIdx + this.resultMinNum) === Number(lotCodes[position])
    },
    getLotCodes (lot) {
      return this.lotType == 0 ? lot.code.split(',').slice(0, 3) :
             this.lotType == 1 ? lot.code.split(',').slice(1, 4) :
             lot.code.split(',').slice(2)
      // return lot.code.split(',')
    },
    generateLotNums (numStr) {
      return numStr.split(',')
    },
    generateResultNum (num) {
      if (this.lotInfo.rule.chart.resultFillZero) {
        return fillDigit(num, 2, '0')
      }
      return num
    },
    showCellContent (lot, subIdx) {
      if (this.showLotNum(subIdx, lot)) {
        return true
      }
      return this.leaksStatus
    },
    generateLotNumClass (lot, subIdx, position) {
      if (this.showLotNum(subIdx, lot, position)) {
        return `lot-num lot-num-col-${position}`
      }
      return 'not-lot-num'
    },
    generateCellContent (lot, idx, subIdx, position) {
      // const setGroup = this.getSubSetGroup()
      const lotCodes = this.getLotCodes(lot)
      if (this.showLotNum(subIdx, lot, position)) {
        if (this.lotInfo.rule.chart.resultFillZero) {
          return fillDigit(lotCodes[position], 2, '0')
        }
        return lotCodes[position]
      } else {
        if (this.showCellContent(lot, subIdx)) {
          return this.getLeaksNum(idx, subIdx, position)
        }
        return ''
      }
    },
    getLeaksNum (idx, subIdx, position) {
      if (!this.tableData) {
        return ''
      }
      const groupNextNumIdx = this.getGroupNextNumIdx(idx, subIdx, position)
      return groupNextNumIdx - idx
    },
    handleDrawLine () {
      setTimeout(() => {
        const tbody = this.$refs.tableBody.$el
        this.lotTd = tbody.firstChild.children[2]
        if (
          tbody &&
          this.tableData &&
          this.tableData.length !== 0
        ) {
          this.canvasW = tbody.offsetWidth
          this.canvasH = tbody.offsetHeight + (this.lotTd.offsetHeight * 2)
          // wait for component mounted
          setTimeout(() => {
            this.canvasDraw = this.$refs.tableCanvas.getContext('2d')
            this.clearCanvas()
            this.canvasDraw.strokeStyle = '#3cb0ec'
            this.drawBallLine()
          }, 10)
        }
      }, 10)
    },
    clearCanvas () {
      const canvas = this.$refs.tableCanvas
      this.canvasDraw.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight)
    },
    drawBallLine () {
      if (this.showLineStatus) {
        for (let i = 0, c = 3; i < c; i++) {
          // if (
          //   i === 0 ||
          //   i === this.compareColIdx
          // ) {
            for (let j = 0, d = this.tableData.length - 1; j < d; j++) {
              const start = this.getBallPosition(i, j)
              const end = this.getBallPosition(i, j + 1)
              this.drawOneLine(start, end)
            }
          // }
        }
      }
    },
    getBallPosition (ballCol, ballIdx) {
      const ball = this.$refs.tableBody.$el.querySelectorAll(`.lot-num-col-${ballCol}`)[ballIdx]
      return {
        x: ball.parentElement.offsetLeft + (this.lotTd.offsetWidth / 2),
        y: ball.parentElement.offsetTop + (this.lotTd.offsetHeight / 2)
      }
    },
    drawOneLine (start, end) {
      const c = this.canvasDraw
      c.beginPath()
      c.moveTo(start.x, start.y)
      c.lineTo(end.x, end.y)
      c.stroke()
    },
    generateformClass (lot, idx, formIdx) {
      // const setGroup = this.getSubSetGroup()
      const checkNum = this.getLotCodes(lot)
      const groupNextformIdx = this.getGroupNextformIdx(idx, formIdx)
      switch (formIdx) {
        case 0:
          // 豹子
          if (isThreeKind(checkNum)) {
            return 'form-col'
          }
          break
        case 1:
          // 順子
          if (isStraight(checkNum)) {
            return 'form-col'
          }
          break
        case 2:
          // 對子
          if (isPair(checkNum)) {
            return 'form-col'
          }
          break
        case 3:
          // 半順
          if (isHalfStraight(checkNum) && !isStraight(checkNum) && !isPair(checkNum)) {
            return 'form-col'
          }
          break
        case 4:
          // 雜六
          if (!isThreeKind(checkNum) && !isStraight(checkNum) &&
              !isPair(checkNum) && !isHalfStraight(checkNum)) {
            return 'form-col'
          }
          break
      }
      if (this.leaksLevelStatus && groupNextformIdx - idx > 0) {
        return 'leaksLevel'
      }
      return 'leaksLevel-no'
    },
    getGroupNextformIdx (idx, formIdx) {
      const nextIdx = this.findformNextIdx(idx, formIdx)
      if (nextIdx === -1) {
        return this.tableData.length
      }
      return nextIdx
    },
    findformNextIdx (idx, formIdx) {
      // const setGroup = this.getSubSetGroup()
      switch (formIdx) {
        case 0:
          return findIndex(this.tableData, (i) => {
            let checkNum = this.getLotCodes(i)
            return isThreeKind(checkNum)
          }, idx)
        case 1:
          return findIndex(this.tableData, (i) => {
            let checkNum = this.getLotCodes(i)
            return isStraight(checkNum)
          }, idx)
        case 2:
          return findIndex(this.tableData, (i) => {
            let checkNum = this.getLotCodes(i)
            return isPair(checkNum)
          }, idx)
        case 3:
          return findIndex(this.tableData, (i) => {
            let checkNum = this.getLotCodes(i)
            return isHalfStraight(checkNum) && !isStraight(checkNum) && !isPair(checkNum)
          }, idx)
        case 4:
          return findIndex(this.tableData, (i) => {
            let checkNum = this.getLotCodes(i)
            return !isThreeKind(checkNum) && !isStraight(checkNum) && !isPair(checkNum) && !isHalfStraight(checkNum)
          }, idx)
      }
    },
    generateformContent (lot, idx, formIdx) {
      // const setGroup = this.getSubSetGroup()
      const checkNum = this.getLotCodes(lot)
      switch (formIdx) {
        case 0:
          // 豹子
          if (isThreeKind(checkNum)) {
            return this.formList[formIdx]
          }
          return this.getformLeaksNum(idx, formIdx)
        case 1:
          // 順子
          if (isStraight(checkNum)) {
            return this.formList[formIdx]
          }
          return this.getformLeaksNum(idx, formIdx)
        case 2:
          // 對子
          if (isPair(checkNum)) {
            return this.formList[formIdx]
          }
          return this.getformLeaksNum(idx, formIdx)
        case 3:
          // 半順
          if (isHalfStraight(checkNum) && !isStraight(checkNum) && !isPair(checkNum)) {
            return this.formList[formIdx]
          }
          return this.getformLeaksNum(idx, formIdx)
        case 4:
          // 雜六
          if (!isThreeKind(checkNum) && !isStraight(checkNum) &&
              !isPair(checkNum) && !isHalfStraight(checkNum)) {
            return this.formList[formIdx]
          }
          return this.getformLeaksNum(idx, formIdx)
      }
    },
    getformLeaksNum (idx, formIdx) {
      if (
        !this.tableData ||
        !this.leaksStatus
      ) {
        return ''
      }
      const groupNextNumIdx = this.getGroupNextformIdx(idx, formIdx)
      return groupNextNumIdx - idx
    },
    generateSelClass (lot, idx, selIdx) {
      const checkNum = this.getLotCodes(lot)
      const groupNextSelIdx = this.getGroupNextSelIdx(idx, selIdx)
      switch (selIdx) {
        case 0:
          // 組三
          if (isPair(checkNum)) {
            return 'sel-col'
          }
          break
        case 1:
          // 組六
          if (isAllDiff(checkNum)) {
            return 'sel-col'
          }
          break
        case 2:
          // 豹子
          if (isThreeKind(checkNum)) {
            return 'sel-col'
          }
          break
      }
      if (this.leaksLevelStatus && groupNextSelIdx - idx > 0) {
        return 'leaksLevel'
      }
      return 'leaksLevel-no'
    },
    getGroupNextSelIdx (idx, selIdx) {
      const nextIdx = this.findSelNextIdx(idx, selIdx)
      if (nextIdx === -1) {
        return this.tableData.length
      }
      return nextIdx
    },
    findSelNextIdx (idx, selIdx) {
      // const setGroup = this.getSubSetGroup()
      switch (selIdx) {
        case 0:
          return findIndex(this.tableData, (i) => {
            let checkNum = this.getLotCodes(i)
            return isPair(checkNum)
          }, idx)
        case 1:
          return findIndex(this.tableData, (i) => {
            let checkNum = this.getLotCodes(i)
            return isAllDiff(checkNum)
          }, idx)
        case 2:
          return findIndex(this.tableData, (i) => {
            let checkNum = this.getLotCodes(i)
            return isThreeKind(checkNum)
          }, idx)
      }
    },
    generateSelContent (lot, idx, selIdx) {
      const checkNum = this.getLotCodes(lot)
      switch (selIdx) {
        case 0:
          // 組三
          if (isPair(checkNum)) {
            return this.selList[selIdx]
          }
          return this.getSelLeaksNum(idx, selIdx)
        case 1:
          // 組六
          if (isAllDiff(checkNum)) {
            return this.selList[selIdx]
          }
          return this.getSelLeaksNum(idx, selIdx)
        case 2:
          // 豹子
          if (isThreeKind(checkNum)) {
            return this.selList[selIdx]
          }
          return this.getSelLeaksNum(idx, selIdx)
      }
    },
    getSelLeaksNum (idx, selIdx) {
      if (
        !this.tableData ||
        !this.leaksStatus
      ) {
        return ''
      }
      const groupNextNumIdx = this.getGroupNextSelIdx(idx, selIdx)
      return groupNextNumIdx - idx
    }
  },
  created () {
    this.initTableData()
  },
  watch: {
    updateStatus (val) {
      if (this.firstLoaded) {
        this.firstLoaded = false
      } else if (val) {
        this.initTableData()
      }
    },
    tableData (val) {
      if (val) {
        this.handleDrawLine()
      }
    },
    selCol (val) {
      this.handleDrawLine()
    },
    showLineStatus (val) {
      this.handleDrawLine()
    },
    pageSize (val) {
      switch (typeof val) {
        case 'string':
          this.query.pageSize = 100
          this.query.date = val
          break
        case 'number':
          this.query.latest = val
          break
      }
      this.initTableData()
    }
  }
}
</script>

<style lang="scss" scoped>
$bg-orange: #fbf6f0;
$bg-blue: #edf8fc;
$bg-green: #ecfcec;
$bg-purple: #ededfc;
$bg-emerald: #edfcf6;
$bg-yellow: #fcfced;
$bg-red: #fceded;
$bg-cyan: #edf2fc;
$bg-default: #edf2fc;

$circle-orange: #ee954b;
$circle-blue: #3594e3;
$circle-green: #55b810;
$circle-purple: #716cfa;
$circle-emerald: #4fb383;
$circle-yellow: #bed458;
$circle-red: #ce38bb;
$circle-cyan: #6bcdd0;
$circle-default: #6bcdd0;

$header-row-height: 43px;
$first-col-width: 12em;
$second-col-width: 15em;
// $col-min-width: 300px;
$num-cell-width: 18px;
.table-container {
  position: relative;
  height: max-content;
  overflow: auto;
}
table {
  // margin: 0 0 6px;
  thead {
    tr {
      th {
        border: 1px solid #dee2e6;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        border: 1px solid #dee2e6;
        vertical-align: middle;
      }
      .error-msg {color: #EB5757;}
      .result-nums-container {
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          margin-right: 5px;
          &:last-child{margin-right: 0;}
        }
      }
      .num-container {
        margin: 0 auto;
      }
      .lot-num {
        color: #ffffff;
        width: $num-cell-width;
        height: $num-cell-width;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 999;
      }
      .not-lot-num {
        width: $num-cell-width;
        height: $num-cell-width;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .leaksLevel {
        background-color: #9595c5 !important;
      }
      .digit-default {
        background-color: $bg-default;
        .lot-num {
          background-color: $circle-default;
        }
      }
      .digit-0 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-1 {
        background-color: $bg-blue;
        .lot-num {
          background-color: $circle-blue;
        }
      }
      .digit-2 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .digit-3 {
        background-color: $bg-purple;
        .lot-num {
          background-color: $circle-purple;
        }
      }
      .digit-4 {
        background-color: $bg-emerald;
        .lot-num {
          background-color: $circle-emerald;
        }
      }
      .digit-5 {
        background-color: $bg-yellow;
        .lot-num {
          background-color: $circle-yellow;
        }
      }
      .digit-6 {
        background-color: $bg-red;
        .lot-num {
          background-color: $circle-red;
        }
      }
      .digit-7 {
        background-color: $bg-cyan;
        .lot-num {
          background-color: $circle-cyan;
        }
      }
      .digit-8 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-9 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .form-col {
        background-color: #46bd95;
      }
      .sel-col {
        background-color: #8585fb;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  // .b-table-sticky-header {
  //   max-height: 600px;
  // }
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
          // left: $first-col-width !important;
        }
        // th:not(:nth-child(1)):not(:nth-child(2)) {
        //   // min-width: $col-min-width;
        //   // min-width: var(--col-min-width);
        // }
      }
      // tr:nth-child(2) {
      //   th {
      //     top: $header-row-height !important;
      //   }
      // }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        // td:nth-child(2) {
        //   // left: $first-col-width !important;
        // }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
</style>
