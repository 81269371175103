<template>
  <div
  class="table-container"
  :class="isMobile && tableData ? 'fixed-height' : ''">
    <canvas
      ref="tableCanvas"
      style="position:absolute;top:0;left:0;z-index:99;"
      :width="canvasW"
      :height="canvasH">
    </canvas>
    <b-table-simple>
      <!-- column group -->
      <!-- 开奖期数 -->
      <colgroup><col></colgroup>
      <!-- 开奖号码 -->
      <colgroup><col></colgroup>
      <!-- 萬位 -->
      <colgroup>
        <col
          v-for="(subHead, subIdx) in subHeadSet"
          :key="'set-sub-col-tt-' + subIdx">
      </colgroup>
      <!-- 個位 -->
      <colgroup>
        <col
          v-for="(subHead, subIdx) in subHeadSet"
          :key="'set-sub-col-d-' + subIdx">
      </colgroup>
      <!-- 龍虎 -->
      <colgroup>
        <col
          v-for="(item, idx) in featureList"
          :key="'type-col-' + idx">
      </colgroup>

      <!-- table head -->
      <b-thead>
        <!-- first row -->
        <b-tr>
          <!-- 开奖期数 -->
          <b-th
            rowspan="2">
            开奖期数
          </b-th>
          <!-- 开奖号码 -->
          <b-th
            rowspan="2">
            开奖号码
          </b-th>
          <!-- 萬位 -->
          <b-th :colspan="subSetLen">
            {{ compareCols[0] }}
          </b-th>
          <!-- 個位 -->
          <b-th :colspan="subSetLen">
            {{ compareCols[1] }}
          </b-th>
          <!-- 龙虎 -->
          <b-th colspan="3">
            龙虎
          </b-th>
        </b-tr>
        <!-- second row -->
        <b-tr>
          <!-- 萬位 -->
          <b-th
            v-for="(subHead, idx) in subHeadSet"
            :key="'sub-head-result-col-tt-' + idx">
            {{ subHead }}
          </b-th>
          <!-- 個位 -->
          <b-th
            v-for="(subHead, idx) in subHeadSet"
            :key="'sub-head-result-col-d-' + idx">
            {{ subHead }}
          </b-th>
          <!-- 龙虎 -->
          <b-th
            v-for="(item, idx) in featureList"
            :key="'sub-head-feature-col-' + idx">
            {{ item }}
          </b-th>
        </b-tr>
      </b-thead>

      <!-- table body -->
      <b-tbody ref="tableBody">
        <b-tr v-if="!tableData">
          <b-td
            :colspan="tableColNum"
            :class="showMsg == 'error' ? 'error-msg' : ''">
            {{tableMsg[showMsg]}}
          </b-td>
        </b-tr>
        <b-tr
          v-else
          v-for="(lot, idx) in tableData"
          :key="lot.id">
          <!-- 开奖期数 -->
          <b-td>{{ lot.issue }}</b-td>
          <!-- 开奖号码 -->
          <b-td>
            <div class="result-nums-container">
              <div
                v-for="(num, numIdx) in generateLotNums(lot.code)"
                :key="'lot-num-' + lot.id + numIdx">{{ generateResultNum(num) }}</div>
            </div>
          </b-td>
          <!-- 萬位 -->
          <b-td
            v-for="(subNum, subIdx) in subHeadSet"
            :key="'row-' + lot.id + '-idx-' + subIdx"
            :class="generateCellClass(lot, idx, subIdx)"
            >
            <div
              class="num-container"
              :class="generateLotNumClass(lot, subIdx)">
              {{ generateCellContent(lot, idx, subIdx) }}
            </div>
          </b-td>
          <!-- 個位 -->
          <b-td
            v-for="(subNum, subIdx) in subHeadSet"
            :key="'row-' + lot.id + '-idx-' + subIdx + '-compare'"
            :class="generateCellClass(lot, idx, subIdx + getLastLotSubIdx(lot.code))">
            <div
              class="num-container"
              :class="generateLotNumClass(lot, subIdx + getLastLotSubIdx(lot.code))">
              {{ generateCellContent(lot, idx, subIdx + getLastLotSubIdx(lot.code)) }}
            </div>
          </b-td>
          <!-- 龙虎 -->
          <b-td
            v-for="(feature, featureIdx) in featureList"
            :key="'row' + lot.id + '-idx-' + featureIdx"
            :class="generateFeatureContainerClass(idx, featureIdx)">
            <div
              class="num-container"
              :class="generateFeatureClass(lot, featureIdx)">
              {{ generateFeatureContent(lot, idx, featureIdx) }}
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { allLots } from '@/constants/lots'
import { getLotWinNumHistory } from '@/api/client/lot'
import { getLotInfoById, isDT } from '@/utils/lot'
import { fillDigit } from '@/utils/string'
import { range, findIndex } from 'lodash'
import { isMobile } from '@/utils/device'

export default {
  props: {
    updateStatus: {
      type: Boolean,
      required: true
    },
    lotTotalIssue: {
      type: Number,
      required: true
    },
    pageSize: {
      type: [Number, String],
      required: true
    },
    leaksStatus: {
      type: Boolean,
      required: true
    },
    leaksLevelStatus: {
      type: Boolean,
      required: true
    },
    showLineStatus: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      firstLoaded: true,
      canvasW: 0,
      canvasH: 0,
      featureList: ['龙', '虎', '合'],
      tableData: null,
      showMsg: '',
      tableMsg: {
        error: '發生错误，请稍后再试',
        empty: '目前尚无资料',
        loading: '获取资料中...'
      }
    }
  },
  computed: {
    lotId: {
      get () {
        if (!this.$route.query.id) {
          return allLots[0].children[0].id
        }
        return this.$route.query.id
      }
    },
    lotInfo: {
      get () {
        return (
          getLotInfoById(this.lotId)
        )
      }
    },
    query: {
      get () {
        const objQuery = {
          // date: '2021-03-01',
          code: this.lotId,
          page: 1
        }

        switch (typeof this.pageSize) {
          case 'string':
            objQuery.date = this.pageSize
            break
          case 'number':
            // objQuery.pageSize = this.pageSize
            // objQuery.date = null
            objQuery.latest = this.pageSize
            break
        }

        return objQuery
      }
    },
    headArr: {
      get () {
        return this.lotInfo.rule.chart.headArr
      }
    },
    compareCols: {
      get () {
        return this.lotInfo.rule.chart.DtCompare
      }
    },
    compareColIdx: {
      get () {
        return findIndex(this.headArr, (o) => {
          return o === this.compareCols[1]
        })
      }
    },
    // for <template />
    resultMinNum: {
      get () {
        return this.lotInfo.rule.chart.resultMinNum
      }
    },
    resultMaxNum: {
      get () {
        return this.lotInfo.rule.chart.resultMaxNum
      }
    },
    subHeadSet: {
      get () {
        return range(this.resultMinNum, this.resultMaxNum + 1)
      }
    },
    tableColNum: {
      get () {
        return (this.subSetLen * this.headArr.length) + 2
      }
    },
    subSetLen: {
      get () {
        return this.subHeadSet.length
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    init () {
      this.showMsg = 'loading'
      getLotWinNumHistory(this.query)
        .then(res => {
          if (res.status === resStatus.OK) {
            if (res.data.length === 0) {
              this.showMsg = 'empty'
              this.emptyPageData()
            } else {
              this.tableData = res.data
            }
          } else {
            this.showMsg = 'error'
            this.emptyPageData()
          }
        })
    },
    emptyPageData () {
      this.tableData = null
      this.canvasW = 0
      this.canvasH = 0
    },
    getQueryPageSize () {
      switch (typeof this.pageSize) {
        case 'string':
          return 50
        case 'number':
          return this.pageSize
      }
    },
    getQueryDate () {
      switch (typeof this.pageSize) {
        case 'string':
          return this.pageSize
        case 'number':
          return null
      }
    },
    generateLotNums (numStr) {
      return numStr.split(',')
    },
    generateResultNum (num) {
      if (this.lotInfo.rule.chart.resultFillZero) {
        return fillDigit(num, 2, '0')
      }
      return num
    },
    getLastLotSubIdx () {
      return this.compareColIdx * this.subSetLen
    },
    // Lot Numbers
    generateCellContent (lot, idx, subIdx) {
      // console.log(lot, idx, subIdx)
      const setGroup = this.getSubSetGroup(subIdx)
      const lotCodes = this.getLotCodes(lot)
      if (this.showLotNum(subIdx, lot)) {
        if (this.lotInfo.rule.chart.resultFillZero) {
          return fillDigit(lotCodes[setGroup], 2, '0')
        }
        return lotCodes[setGroup]
      } else {
        if (this.showCellContent(subIdx, lot)) {
          return this.getLeaksNum(idx, subIdx)
        }
        return ''
      }
    },
    getSubSetGroup (subIdx) {
      return Math.floor(subIdx / this.subSetLen)
    },
    getLotCodes (lot) {
      return lot.code.split(',')
    },
    showLotNum (subIdx, lot) {
      const setGroup = this.getSubSetGroup(subIdx)
      const lotCodes = this.getLotCodes(lot)
      return (subIdx + this.resultMinNum) === setGroup * this.subSetLen + Number(lotCodes[setGroup])
    },
    showCellContent (subIdx, lot) {
      if (this.showLotNum(subIdx, lot)) {
        return true
      }
      return this.leaksStatus
    },
    getLeaksNum (idx, subIdx) {
      if (!this.tableData) {
        return ''
      }
      const groupNextNumIdx = this.getGroupNextNumIdx(idx, subIdx)
      return groupNextNumIdx - idx
    },
    getGroupNextNumIdx (idx, subIdx) {
      const setGroup = this.getSubSetGroup(subIdx)
      const subIdxNum = subIdx % this.subSetLen + this.resultMinNum
      const nextIdx = findIndex(this.tableData, (i) => {
        return Number(i.code.split(',')[setGroup]) === subIdxNum
      }, idx)
      if (nextIdx === -1) {
        return this.tableData.length
      }
      return nextIdx
    },
    generateCellClass (lot, idx, subIdx) {
      const cellClass = `digit-default digit-${this.getSubSetGroup(subIdx)}`
      const groupNextNumIdx = this.getGroupNextNumIdx(0, subIdx)
      if (
        !this.showLotNum(subIdx, lot) &&
        this.leaksLevelStatus &&
        groupNextNumIdx - idx > 0
      ) {
        return `${cellClass} leaksLevel`
      }
      return `${cellClass} leaksLevel-no`
    },
    generateLotNumClass (lot, subIdx) {
      if (this.showLotNum(subIdx, lot)) {
        return `lot-num lot-num-col-${this.getLotCol(subIdx)}`
      }
      return 'not-lot-num'
    },
    getLotCol (subIdx) {
      return Math.floor(subIdx / (this.resultMaxNum - this.resultMinNum + 1))
    },
    // Feature
    generateFeatureContainerClass (idx, featureIdx) {
      const basicClass = `feature-container-${featureIdx}`
      const groupNextFeatureIdx = this.getGroupNextFeatureIdx(0, featureIdx)
      if (
        this.leaksLevelStatus &&
        groupNextFeatureIdx - idx > 0
      ) {
        return `${basicClass} leaksLevel`
      }
      return `${basicClass} leaksLevel-no`
    },
    generateFeatureClass (lot, featureIdx) {
      const checkNums = this.getLotCodes(lot)

      switch (featureIdx) {
        case 0:
          // 龙
          if (isDT(checkNums[0], checkNums[this.compareColIdx]) === 'd') {
            return 'lot-num feature-item'
          }
          break
        case 1:
          // 虎
          if (isDT(checkNums[0], checkNums[this.compareColIdx]) === 't') {
            return 'lot-num feature-item'
          }
          break
        case 2:
          // 合
          if (isDT(checkNums[0], checkNums[this.compareColIdx]) === 'e') {
            return 'lot-num feature-item'
          }
          break
      }
    },
    generateFeatureContent (lot, idx, featureIdx) {
      const checkNums = this.getLotCodes(lot)
      switch (featureIdx) {
        case 0:
          // 龙
          if (isDT(checkNums[0], checkNums[this.compareColIdx]) === 'd') {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
        case 1:
          // 虎
          if (isDT(checkNums[0], checkNums[this.compareColIdx]) === 't') {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
        case 2:
          // 合
          if (isDT(checkNums[0], checkNums[this.compareColIdx]) === 'e') {
            return this.featureList[featureIdx]
          }
          return this.getFeatureLeaksNum(idx, featureIdx)
      }
    },
    getFeatureLeaksNum (idx, featureIdx) {
      if (
        !this.tableData ||
        !this.leaksStatus
      ) {
        return ''
      }
      const groupNextNumIdx = this.getGroupNextFeatureIdx(idx, featureIdx)
      return groupNextNumIdx - idx
    },
    getGroupNextFeatureIdx (idx, featureIdx) {
      const nextIdx = this.findFeatureNextIdx(idx, featureIdx)
      if (nextIdx === -1) {
        // if (this.tableData.length < this.query.latest) {
        return this.tableData.length
        // }
        // return this.query.latest
      }
      return nextIdx
    },
    findFeatureNextIdx (idx, featureIdx) {
      // const setGroup = this.getSubSetGroup()
      switch (featureIdx) {
        case 0:
          return findIndex(this.tableData, (i) => {
            const lotNums = i.code.split(',')
            return isDT(lotNums[0], lotNums[this.compareColIdx]) === 'd'
          }, idx)
        case 1:
          return findIndex(this.tableData, (i) => {
            const lotNums = i.code.split(',')
            return isDT(lotNums[0], lotNums[this.compareColIdx]) === 't'
          }, idx)
        case 2:
          return findIndex(this.tableData, (i) => {
            const lotNums = i.code.split(',')
            return isDT(lotNums[0], lotNums[this.compareColIdx]) === 'e'
          }, idx)
      }
    },
    // handle canvas
    handleDrawLine () {
      setTimeout(() => {
        const tbody = this.$refs.tableBody.$el
        this.lotTd = tbody.offsetHeight > 0 ? tbody.firstChild.children[2] : null
        if (
          tbody &&
          this.tableData &&
          this.tableData.length !== 0 &&
          this.lotTd
        ) {
          this.canvasW = tbody.offsetWidth
          this.canvasH = tbody.offsetHeight + (this.lotTd.offsetHeight * 2)
          // wait for component mounted
          setTimeout(() => {
            this.canvasDraw = this.$refs.tableCanvas.getContext('2d')
            this.clearCanvas()
            this.canvasDraw.strokeStyle = '#3cb0ec'
            this.drawBallLine()
          }, 10)
        } else {
          this.clearCanvas()
        }
      }, 10)
    },
    clearCanvas () {
      const canvas = this.$refs.tableCanvas
      if (this.canvasDraw) {
        this.canvasDraw.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight)
      }
    },
    drawBallLine () {
      if (this.showLineStatus) {
        for (let i = 0, c = this.headArr.length; i < c; i++) {
          if (
            i === 0 ||
            i === this.compareColIdx
          ) {
            for (let j = 0, d = this.tableData.length - 1; j < d; j++) {
              const start = this.getBallPosition(i, j)
              const end = this.getBallPosition(i, j + 1)
              this.drawOneLine(start, end)
            }
          }
        }
      }
    },
    getBallPosition (ballCol, ballIdx) {
      const ball = this.$refs.tableBody.$el.querySelectorAll(`.lot-num-col-${ballCol}`)[ballIdx]
      const retObj = {
        x: ball.parentElement.offsetLeft,
        y: ball.parentElement.offsetTop
      }
      if (this.lotTd) {
        retObj.x += this.lotTd.offsetWidth / 2
        retObj.y += this.lotTd.offsetHeight / 2
      }
      return retObj
    },
    drawOneLine (start, end) {
      // console.log('draw one line: ', start, end)
      const c = this.canvasDraw
      c.beginPath()
      c.moveTo(start.x, start.y)
      c.lineTo(end.x, end.y)
      c.stroke()
    }
  },
  created () {
    this.init()
  },
  watch: {
    updateStatus (val) {
      if (this.firstLoaded) {
        this.firstLoaded = false
      } else if (val) {
        this.init()
      }
    },
    pageSize (val) {
      switch (typeof val) {
        case 'string':
          // this.query.pageSize = this.lotTotalIssue
          this.query.pageSize = 100
          this.query.date = val
          break
        case 'number':
          this.query.latest = val
          // this.query.pageSize = val
          // this.query.date =
          break
      }
      this.init()
    },
    tableData (val) {
      if (val) {
        this.handleDrawLine()
      }
    },
    showLineStatus (val) {
      this.handleDrawLine()
    }
  }
}
</script>

<style lang="scss" scoped>
$bg-orange: #fbf6f0;
$bg-blue: #edf8fc;
$bg-green: #ecfcec;
$bg-purple: #ededfc;
$bg-emerald: #edfcf6;
$bg-yellow: #fcfced;
$bg-red: #fceded;
$bg-cyan: #edf2fc;
$bg-default: #edf2fc;

$circle-orange: #ee954b;
$circle-blue: #3594e3;
$circle-green: #55b810;
$circle-purple: #716cfa;
$circle-emerald: #4fb383;
$circle-yellow: #bed458;
$circle-red: #ce38bb;
$circle-cyan: #6bcdd0;
$circle-default: #6bcdd0;

$header-row-height: 43px;
$first-col-width: 12em;
$second-col-width: 15em;
// $col-min-width: 300px;
$num-cell-width: 18px;
.table-container {
  position: relative;
  height: max-content;
  overflow: auto;
}
table {
  // margin: 0 0 6px;
  thead {
    tr {
      th {
        border: 1px solid #dee2e6;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        border: 1px solid #dee2e6;
        vertical-align: middle;
      }
      .error-msg {color: #EB5757;}
      .result-nums-container {
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          margin-right: 5px;
          &:last-child{margin-right: 0;}
        }
      }
      .num-container {
        margin: 0 auto;
      }
      .lot-num {
        color: #ffffff;
        width: $num-cell-width;
        height: $num-cell-width;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 999;
      }
      .not-lot-num {
        width: $num-cell-width;
        height: $num-cell-width;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .leaksLevel {
        background-color: #9595c5 !important;
        color: #ffffff;
      }
      .digit-default {
        background-color: $bg-default;
        .lot-num {
          background-color: $circle-default;
        }
      }
      .digit-0 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-1 {
        background-color: $bg-blue;
        .lot-num {
          background-color: $circle-blue;
        }
      }
      .digit-2 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .digit-3 {
        background-color: $bg-purple;
        .lot-num {
          background-color: $circle-purple;
        }
      }
      .digit-4 {
        background-color: $bg-emerald;
        .lot-num {
          background-color: $circle-emerald;
        }
      }
      .digit-5 {
        background-color: $bg-yellow;
        .lot-num {
          background-color: $circle-yellow;
        }
      }
      .digit-6 {
        background-color: $bg-red;
        .lot-num {
          background-color: $circle-red;
        }
      }
      .digit-7 {
        background-color: $bg-cyan;
        .lot-num {
          background-color: $circle-cyan;
        }
      }
      .digit-8 {
        background-color: $bg-orange;
        .lot-num {
          background-color: $circle-orange;
        }
      }
      .digit-9 {
        background-color: $bg-green;
        .lot-num {
          background-color: $circle-green;
        }
      }
      .feature-container-0 {
        .feature-item {
          background-color: $circle-green;
        }
      }
      .feature-container-1 {
        .feature-item {
          background-color: $circle-purple;
        }
      }
      .feature-container-2 {
        .feature-item {
          background-color: $circle-green;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-container {
    position: relative;
    overflow: auto;
  }
  .fixed-height{
    height: calc(100vh - 95px - 52px - 100px);
  }
  table {
    color: #4a4a4a;
    thead {
      tr{
        &:nth-child(1) {
          & > th {
            &:nth-child(1) {min-width: 6.2em;}
            &:nth-child(2) {min-width: 6.2em;}
          }
        }
        th {
          border-top: none;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  // .b-table-sticky-header {
  //   max-height: 600px;
  // }
  table {
    thead {
      tr:nth-child(1) {
        th:nth-child(1) {
          min-width: $first-col-width;
        }
        th:nth-child(2) {
          min-width: $second-col-width;
        }
      }
      tr {
        th {
          padding: 0.75em;
        }
      }
    }
    tbody {
      tr {
        .result-nums-container {
          // gap: 5px;
          & > div {
            margin-right: 5px;
            &:last-child {margin-right: 0;}
          }
        }
        td {
          padding: 0.75em;
        }
      }
    }
  }
}
</style>
