<template>
  <div class="tab-container">
    <div
      class="chat-item"
      v-for="(item, idx) in content"
      :key="item.id || new Date() + idx">
      <div
        v-if="item.rule"
        class="logo-container">
        <img :src="getLogoUrl(item)" >
      </div>
      <div class="direct-container">
        <div class="analysis direct-sec">
          <div
            v-for="analysis in getAnalysisItems(item)"
            :key="analysis.id">
            <router-link :to="generateRouteLink(item, analysis)">{{ analysis.context }}</router-link>
          </div>
        <!-- </div>
        <div class="trend direct-sec"> -->
          <div
            v-for="trend in getTrendItems(item)"
            :key="trend.id">
            <router-link :to="generateRouteLink(item, trend)">{{ trend.context }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'
import { getLotTabById } from '@/utils/lot'
import { isMobile } from '@/utils/device'

export default {
  data () {
    return {
      tab: 'template'
    }
  },
  computed: {
    content: {
      get () {
        let result = allLots
          .filter(item => {
            return item.id === this.tab
          })[0]
          .children

        if (result.length % 2 !== 0 && !isMobile()) {
          result = [...result, {}]
        }
        return result
      }
    }
  },
  methods: {
    getLogoUrl (item) {
      if (item.logo) {
        return item.logo
      }
      return ''
    },
    getAnalysisItems (item) {
      if (
        !item.rule ||
        !item.rule.chart
      ) {
        return []
      }
      return item.rule.chart.analysis
    },
    getTrendItems (item) {
      if (
        !item.rule ||
        !item.rule.chart
      ) {
        return []
      }
      return item.rule.chart.trend
    },
    generateRouteLink (item, chart) {
      const retRoute = {
        name: 'LotteryChartDetail',
        query: {
          id: item.id,
          chart: chart.id
        }
      }
      if (chart.id === 'historyNum') {
        retRoute.query.tab = getLotTabById(item.id).id
      }
      return retRoute
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-container {
  background-color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  .chat-item {
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    .logo-container {
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
    .direct-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .direct-sec {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        a {
          color: #4F4F4F;
        }
        a:hover {
          color: #005FDE;
        }
      }
    }
    &:nth-last-child(1), &:nth-last-child(2) {border: none;}
  }
}
@media (max-width: 575.98px) {
  .tab-container {
    padding: 0;
    .chat-item {
      align-items: center;
      width: 100%;
      min-width: 100%;
      padding: 18px 20px;
      // gap: 20px;
      .logo-container {
        width: 70px;
        height: 70px;
        min-width: 70px;
        min-height: 70px;
      }
      .direct-container {
        // gap: 10px;
        & > div {
          &:last-child {margin-bottom: -10px;}
        }
        .direct-sec {
          // gap: 10px;
          justify-content: flex-start;
          font-size: 16px;
          line-height: 20px;
          text-align: left;
          & > div {
            width: 33.3%;
            min-width: 5em;
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
      &:nth-last-child(2) {border-bottom: 1px solid #E0E0E0;}
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .tab-container {
    padding: 0;
    .chat-item {
      align-items: center;
      width: 100%;
      min-width: 100%;
      padding: 18px 20px;
      // gap: 20px;
      .logo-container {
        width: 70px;
        height: 70px;
        min-width: 70px;
        min-height: 70px;
      }
      .direct-container {
        // gap: 10px;
        & > div {
          margin-bottom: 0;
          &:last-child {margin-bottom: -10px;}
        }
        .direct-sec {
          // gap: 10px;
          justify-content: flex-start;
          font-size: 16px;
          line-height: 20px;
          text-align: left;
          & > div {
            width: 20%;
            min-width: 5.6em;
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .tab-container {
    padding: 0 25px;
    .chat-item {
      width: 50%;
      min-width: 50%;
      padding: 40px 15px;
      // gap: 20px;
      .logo-container {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      .direct-container {
        flex-grow: 99;
        // gap: 10px;
        & > div {
          &:last-child {margin-bottom: -10px;}
        }
        .direct-sec {
          // gap: 10px;
          font-size: 15px;
          & > div {
            margin-right: 16px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .tab-container {
    padding: 0 25px;
    .chat-item {
      width: 50%;
      min-width: 50%;
      padding: 40px 15px;
      // gap: 20px;
      .logo-container {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      .direct-container {
        flex-grow: 99;
        // gap: 10px;
        & > div {
          &:last-child {margin-bottom: -10px;}
        }
        .direct-sec {
          // gap: 10px;
          font-size: 15px;
          & > div {
            margin-right: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .tab-container {
    padding: 0 25px;
    .chat-item {
      width: 50%;
      min-width: 50%;
      padding: 40px 15px;
      // gap: 20px;
      .logo-container {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      .direct-container {
        flex-grow: 99;
        // gap: 10px;
        & > div {
          &:last-child {margin-bottom: -10px;}
        }
        .direct-sec {
          // gap: 10px;
          font-size: 15px;
          & > div {
            margin-right: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
