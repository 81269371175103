<template>
  <div class="lot-detail-container">
    <!-- <button @click="handleShowData">show data</button> -->
    <div class="chart-lot">
      <div class="icon-container">
        <div class="img-container">
          <img :src="lotLogo" />
        </div>
        <h2>{{ lotName }}</h2>
      </div>
      <div class="main-container">
        <div class="lot-cur-result">
          <div class="lot-info">
            <span class="lot-name">{{ lotName }}</span>第 <span class="lot-now-issue">{{ lotLastIssue }}</span> 期开奖
          </div>
          <div class="lot-nums">
            <div
              class="lot-num"
              v-for="(num, idx) in lotNums"
              :key="'num' + idx + num">
              {{ generateLotNum(num) }}
            </div>
          </div>
          <next-result
            v-if="isMobile"
            class="lot-next-result"
            :lotData="lotData"
            :lotStatus="lotStatus"
            :lotNextDate="lotNextDate"
            :dateNow="dateNow"/>
        </div>
        <div class="lot-chart">
          <div>{{lotChartTitle}}</div>
          <div class="chart-item-list">
            <div class="chart-items">
              <div
                v-for="(item, idx) in getItems('analysis')"
                :key="item.id"
                class="chart-item"
                @click="changeChartItem(idx)">
                <router-link :to="generateRouteLink(content, item)">{{ item.context }}</router-link>
              </div>
              <div
                v-for="item in getItems('trend')"
                :key="item.id"
                class="chart-item">
                <router-link :to="generateRouteLink(content, item)">{{ item.context }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <next-result
        v-if="!isMobile"
        class="lot-next-result"
        :lotData="lotData"
        :lotStatus="lotStatus"
        :lotNextDate="lotNextDate"
        :dateNow="dateNow"/>
    </div>
    <div class="content">
      <keep-alive>
        <component
          :is="chart"
          :updateStatus="updateStatus"
          :lotTotalIssue="lotTotalIssue"
          :lotLottery="lotLottery"
          @handleUpdateStatus="handleUpdateStatus" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import ChartContent from '../contents/Template'
import { allLots } from '@/constants/lots'
import { getLotWinNum } from '@/api/client/lot'
import { dateFormat } from '@/utils/time'
import { fillDigit } from '@/utils/string'
import { getLotInfoById } from '@/utils/lot'
import { isMobile } from "@/utils/device"
import NextResult from './NextResult.vue'
import moment from 'moment'
// Analysis
import NumAnalysis from './contents/NumAnalysis'
import HistoryNum from './contents/HistoryNum'
import LzAnalysis from './contents/LzAnalysis'
import HcAnalysis from './contents/HcAnalysis'
import DslsHistory from './contents/DslsHistory'
import Dslslz from './contents/Dslslz'
import Dtlz from './contents/Dtlz'
// Trend
import BasicTrend from './contents/BasicTrend'
import DyTrend from './contents/DyTrend'
import DtTrend from './contents/DtTrend'
import XtTrend from './contents/XtTrend'
import NumTrend from './contents/NumTrend'
import ChampionTrend from './contents/ChampionTrend'
import HzTrend from './contents/HzTrend'
import OeTrend from './contents/OeTrend'
import LsTrend from './contents/LsTrend'
import DsTrend from './contents/DsTrend'
import ZhTrend from './contents/ZhTrend'
import ZtTrend from './contents/ZtTrend'

export default {
  components: {
    // Analysis
    NumAnalysis,
    HistoryNum,
    LzAnalysis,
    HcAnalysis,
    DslsHistory,
    Dslslz,
    Dtlz,
    // Trend
    BasicTrend,
    DyTrend,
    DtTrend,
    XtTrend,
    NumTrend,
    ChampionTrend,
    HzTrend,
    OeTrend,
    LsTrend,
    DsTrend,
    ZhTrend,
    ZtTrend,
    NextResult
  },
  data () {
    return {
      lotData: null,
      dateNow: null,
      countDown: null,
      updateStatus: false,
      lotStatus: 'open' // open, continue, close, error
    }
  },
  computed: {
    query: {
      get () {
        return {
          code: this.lotId
        }
      }
    },
    lotId: {
      get () {
        if (!this.$route.query.id) {
          return allLots[0].children[0].id
        }
        return this.$route.query.id
      }
    },
    lotInfo: {
      get () {
        return getLotInfoById(this.lotId)
      }
    },
    chart: {
      get () {
        if (!this.$route.query.chart) {
          return this.content.rule.chart.analysis[0].id
        }
        return this.$route.query.chart
      }
    },
    content: {
      get () {
        // const result = allLots
        //   // find tab
        //   .filter(item => {
        //     return (
        //       item.children
        //         .filter(lot => {
        //           return lot.id === this.lotId
        //         })
        //         .length !== 0
        //     )
        //   })[0]
        //   .children
        //   // find lot
        //   .filter(lot => {
        //     return lot.id === this.lotId
        //   })[0]
        return getLotInfoById(this.lotId)
      }
    },
    lotLogo: {
      get () {
        if (!this.content.logo) {
          return ''
        }
        return this.content.logo
      }
    },
    lotName: {
      get () {
        if (!this.content.name) {
          return ''
        }
        return this.content.name
      }
    },
    lotLottery: {
      get () {
        if (!this.lotData) {
          return null
        }
        return this.lotData.lottery
      }
    },
    lotLastIssue: {
      get () {
        if (!this.lotLottery) {
          return ''
        }
        return this.lotLottery.last_issue
      }
    },
    lotNextDate: {
      set (val) {
        this.lotData.lottery.next_opendate = val
      },
      get () {
        if (!this.lotLottery) {
          return null
        }
        return new Date(moment(this.lotLottery.next_opendate).format())
      }
    },
    lotTotalIssue: {
      get () {
        if (!this.lotLottery) {
          return 0
        }
        return this.lotLottery.total_issue
      }
    },
    dirtyLotNums: {
      get () {
        if (!this.lotData) {
          return []
        }
        return this.lotData.awards[0].code.split('+')
      }
    },
    lotNums: {
      get () {
        if (this.dirtyLotNums.length === 0) {
          return []
        }
        return this.dirtyLotNums[0].split(',')
      }
    },
    plusLotNum: {
      get () {
        if (this.dirtyLotNums.length === 0) {
          return null
        }
        return this.dirtyLotNums[1]
      }
    },
    lotChartTitle: {
      get () {
        return this.isMobile ? '走势图表' : '走势图表 :'
      }
    },
    isMobile: {
      get () {
        return isMobile ()
      }
    }
  },
  methods: {
    handleUpdateStatus (val) {
      this.updateStatus = val
    },
    handleShowData () {
      console.log('content: ', this.content)
    },
    initPage () {
      this.getPageData()
        .then(res => {
          if (res !== 0) {
            this.setReload()
          }
        })
    },
    setReload () {
      setTimeout(() => {
        this.initPage()
        // this.reload = setInterval(this.initPage(), 5000)
      }, 5000)
    },
    // stopReload () {
    //   clearInterval(this.reload)
    // },
    getPageData () {
      return getLotWinNum(this.query)
        .then(res => {
          if (res.status === resStatus.OK) {
            if (
              !this.lotData ||
              this.isNewData(res)
            ) {
              this.lotData = res.data
              this.initTime()
              // this.stopReload()
              this.updateStatus = true
              this.setLotStatus(res)
              return new Promise(resolve => {
                resolve(0)
              })
            } else {
              return new Promise(resolve => {
                resolve(404)
              })
            }
          }
        })
        // .catch(err => {
        //   return new Promise((resolve, reject) => {
        //     reject(new Error('fail'))
        //   })
        // })
    },
    isNewData (res) {
      return this.lotLottery.last_issue !== res.data.lottery.last_issue
    },
    initTime () {
      this.countDown = setInterval(() => {
        this.dateNow = Date.now()
      }, 1000)
    },
    setLotStatus (res) {
      this.lotStatus = res.data.lottery.status
    },
    getRouteQuery () {
      return this.$route.query
    },
    generateLotNum (num) {
      if (this.lotInfo.rule.chart.resultFillZero) {
        return fillDigit(num, 2, '0')
      }
      return num
    },
    getItems (type) {
      const chartTypes = this.content.rule.chart
      if (!chartTypes[type]) {
        return []
      }
      return chartTypes[type]
    },
    generateRouteLink (item, chart) {
      return ChartContent.methods.generateRouteLink(item, chart)
    },
    curTime (date) {
      // return dateFormat('yyyy-MM-dd hh:mm:ss', date)
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    changeChartItem (idx) {
      if (isMobile()) {
        document.querySelector('.lot-detail-container .lot-chart .chart-item-list').scroll({
          left: idx * 60,
          behavior: 'smooth'
        })
      }
    }
  },
  created () {
    this.initPage()
  },
  watch: {
    dateNow (val) {
      if (this.curTime(val) >= this.curTime(this.lotNextDate)) {
        clearInterval(this.countDown)
        this.lotNextDate = val
        this.initPage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lot-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  border-radius: 5px;
  .chart-lot {
    display: flex;
    max-width: 100vw;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 99;
    position: relative;
    box-shadow: 0px 5px 20px -10px #dddddd;
    margin-bottom: 2px;
    background-color: #ffffff;
    border-radius: 5px 5px 0 0;
    .icon-container {
      display: flex;
      width: 210px;
      min-width: 210px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 70px 0 0;
      .img-container {
        width: 100px;
        height: 100px;
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      h2 {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
    .main-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex-grow: 99;
      margin: 40px 0 24px;
      .lot-cur-result {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 26px;
        .lot-info {
          font-size: 21px;
          margin-bottom: 6px;
          color: #828282;
          .lot-name {
            color: #005FDE;
            margin-right: 10px;
            font-weight: 500;
          }
          .lot-now-issue {
            color: #EB5757;
          }
        }
        .lot-nums {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          min-height: 50px;
          .lot-num {
            display: flex;
            width: 40px;
            height: 40px;
            color: #FFFFFF;
            font-size: 20px;
            background-color: #2D9CDB;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-top: 10px;
            margin-right: 10px;
          }
        }
      }
      .lot-chart {
        display: flex;
        font-size: 16px;
        line-height: 35px;
        padding: 20px 0 0;
        border-top: 1px solid #E0E0E0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        div:first-child {
          width: fit-content;
          min-width: fit-content;
        }
        .chart-item-list{
          width: 100%;
          .chart-items {
            display: flex;
            flex-wrap: wrap;
            margin-left: 20px;
            div {
              margin-right: 20px;
              &:last-child {margin-right: 0;}
              a {
                color: #4F4F4F;
                &:hover {color: #005FDE;}
              }
            }
            .router-link-exact-active {
              color: #005FDE;
            }
          }
        }
      }
    }
  }
  .content {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 0 0 5px 5px;
  }
}
@media (max-width: 575.98px) {
  .lot-detail-container {
    .chart-lot {
      flex-direction: column;
      border-radius: 0;
      .icon-container {
        display: none;
      }
      .main-container {
        width: 100%;
        padding: 20px 15px 0;
        margin: 0;
        .lot-cur-result {
          width: 100%;
          padding-bottom: 20px;
          margin-bottom: 0;
          border-bottom: 1px solid #E0E0E0;
          .lot-info {
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 10px;
            .lot-now-issue {font-weight: 500;}
          }
          .lot-nums {
            margin-bottom: 5px;
            min-height: 10px;
            .lot-num {
              width: 32px;
              height: 32px;
              font-size: 18px;
              margin-top: 0;
              margin-bottom: 10px;
            }
          }
        }
        .lot-chart {
          flex-direction: column;
          font-size: 14px;
          line-height: 32px;
          padding: 15px 0 0;
          border-top: none;
          &>div:first-child {font-weight: 500;}
          .chart-item-list {
              width: 100vw;
              overflow-x: scroll;
              text-align: left;
              &::-webkit-scrollbar {
                display: none;
              }
            .chart-items {
              display: inline-flex;
              width: max-content;
              margin-left: 0;
              margin-top: 5px;
              padding-right: 15px;
              padding-bottom: 15px;
              flex-wrap: nowrap;
              div {
                margin-right: 15px;
                &:last-child {margin-right: 15px;}
                & > a{
                  display: block;
                  padding: 6px 12px;
                  line-height: 20px;
                  border-radius: 30px;
                  background-color: #F2F2F2;
                }
              }
              .router-link-exact-active {
                background-color: #E0F5FF;
              }
            }
          }
        }
      }
    }
    .content {
      width: 100%;
      background-color: #F3F9FF;
      border: 0;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lot-detail-container {
    .chart-lot {
      flex-direction: column;
      border-radius: 0;
      .icon-container {
        display: none;
      }
      .main-container {
        width: 100%;
        padding: 20px 15px 0;
        margin: 0;
        .lot-cur-result {
          width: 100%;
          padding-bottom: 20px;
          margin-bottom: 0;
          border-bottom: 1px solid #E0E0E0;
          .lot-info {
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 10px;
            .lot-now-issue {font-weight: 500;}
          }
          .lot-nums {
            margin-bottom: 5px;
            min-height: 10px;
            .lot-num {
              width: 32px;
              height: 32px;
              font-size: 18px;
              margin-top: 0;
              margin-bottom: 10px;
            }
          }
        }
        .lot-chart {
          flex-direction: column;
          font-size: 14px;
          line-height: 32px;
          padding: 15px 0 0;
          border-top: none;
          &>div:first-child {font-weight: 500;}
          .chart-item-list {
              width: 100vw;
              overflow-x: scroll;
              text-align: left;
              &::-webkit-scrollbar {
                display: none;
              }
            .chart-items {
              display: inline-flex;
              width: max-content;
              margin-left: 0;
              margin-top: 5px;
              padding-right: 15px;
              padding-bottom: 15px;
              flex-wrap: nowrap;
              div {
                margin-right: 15px;
                &:last-child {margin-right: 15px;}
                & > a{
                  display: block;
                  padding: 6px 12px;
                  line-height: 20px;
                  border-radius: 30px;
                  background-color: #F2F2F2;
                }
              }
              .router-link-exact-active {
                background-color: #E0F5FF;
              }
            }
          }
        }
      }
    }
    .content {
      width: 100%;
      background-color: #F3F9FF;
      border-radius: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-detail-container {
    .chart-lot {
      .icon-container {
        width: fit-content;
        min-width: fit-content;
        padding: 0 30px 0 25px;
        margin: 52px 0 8px;
        .img-container{
          width: 90px;
          height: 90px;
        }
        h2 {
          font-size: 16px;
        }
      }
      .main-container {
        .lot-cur-result {
          margin-bottom: 20px;
          .lot-info {font-size: 18px;}
          .lot-nums {
            min-height: 32px;
            .lot-num {
              width: 32px;
              height: 32px;
              font-size: 18px;
            }
          }
        }
        .lot-chart {
          font-size: 14px;
          line-height: 32px;
          padding: 15px 0 0;
          .chart-items {
            margin-left: 15px;
            div {
              margin-right: 15px;
              &:last-child {margin-right: 0;}
            }
          }
        }
      }
    }
    .content {
      width: 100%;
      // background-color: #ffffff;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lot-detail-container {
    .chart-lot {
      .icon-container {
        width: 200px;
        min-width: 200px;
      }
    }
    .content {
      width: 100%;
      // background-color: #ffffff;
    }
  }
}
@media (min-width: 1200px) {}
</style>
